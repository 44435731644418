/* global google */
import { Buffer } from "buffer";

import { VESSEL_TYPE } from "../../model/slice/vesselSlice.js";
import {
  getAlarmHighestPriority,
  getHighlightStyle,
  getAlarmFill,
} from "../../helpers/alarmTypeStyle.js";

import VesselIcon from "../vesselImages/vesselIcon.js";

const getAlarmMarker = (filter) => `
<svg width="50px" height="50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
  <circle cx="25" cy="25" r="5" stroke-width="3" fill="${filter}">
    <animate 
      attributeName="r"
      from="6"
      to="25"
      dur="3s"
      begin="0s"
      repeatCount="indefinite"
      fill="freeze" 
      id="circ-anim"
      />
    <animate 
      attributeType="CSS" attributeName="opacity"
      from="0.7"
      to="0"
      dur="3s"
      begin="0s"
      repeatCount="indefinite"
      fill="freeze" 
      id="circ-anim"
      />
  </circle>
  <circle cx="25" cy="25" r="5" stroke-width="3" fill="${filter}">
    <animate 
      attributeName="r"
      from="6"
      to="25"
      dur="3s"
      begin="1.5s"
      repeatCount="indefinite"
      fill="freeze" 
      id="circ-anim"
      />
    <animate 
      attributeType="CSS" attributeName="opacity"
      from="0.7"
      to="0"
      dur="3s"
      begin="1.5s"
      repeatCount="indefinite"
      fill="freeze" 
      id="circ-anim"
      />
  </circle>
</svg>`;

export const addVesselMarker = (vesselId, map, object, isVesselDisconnected, isSelected) => {
  const isSEPvessel = object?.vesselType === VESSEL_TYPE.SEP;
  const shipType =
    object.vesselLatestAisData?.length === 0 ? 0 : object.vesselLatestAisData[0].shipType;
  const rotation = object.vesselLatestHehdtData[0]?.head || 0;
  //let rotation = 280;
  const vesselIconSVGString = VesselIcon(
    isSEPvessel ? 52 : shipType,
    rotation,
    1,
    isVesselDisconnected,
    isSelected
  );

  const vesselImg = document.createElement("img");

  vesselImg.style.transform = 'translateY(50%) scale(1)';
  vesselImg.src = vesselIconSVGString;

  return new google.maps.marker.AdvancedMarkerElement({
    position: {
      lat: object.vesselLatestGpggaData[0].latitude,
      lng: object.vesselLatestGpggaData[0].longitude,
    },
    map,
    content: vesselImg,
    /*icon: {
      url: vesselIcon,
      rotation,
      size: isSelected ? new google.maps.Size(40, 40) : new google.maps.Size(24, 24),
      anchor: new google.maps.Point(
        adjustedValue + adjustedAnchorValue,
        adjustedValue + adjustedAnchorValue
      ),
    },*/
    zIndex: vesselId === object.vessel_id ? google.maps.Marker.MAX_ZINDEX - 1 : 300,
  });
};

export const selectedVesselMarkerURL = (
  isSEPvessel,
  shipType,
  rotation,
  isVesselDisconnected,
  isSelected
) => {
  return VesselIcon(isSEPvessel ? 52 : shipType, rotation, 1, isVesselDisconnected, isSelected);
};

const getMarkerData = (alarmHighestPriority) => {
  const alarmMarker = getAlarmMarker(
    alarmHighestPriority != null ? getAlarmFill(alarmHighestPriority) : ""
  );
  return "data:image/svg+xml;base64," + Buffer.from(alarmMarker, "binary").toString("base64");
};

export const addAlarmMarker = (vesselId, map, fleetAlarmData, object, idx) => {
  const alarmData = fleetAlarmData.filter((f) => f.vesselId === object.vessel_id);
  if (alarmData?.length > 0) {
    const alarmHighestPriority = getAlarmHighestPriority(alarmData);
    if (alarmHighestPriority.alarmStatus === "ABNOR") {
      const marker = getMarkerData(alarmHighestPriority);

      const markerImg = document.createElement("img");

      markerImg.style.transform = 'translateY(50%) scale(1)';
      markerImg.src = marker;
      return new google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: object.vesselLatestGpggaData[0].latitude,
          lng: object.vesselLatestGpggaData[0].longitude,
        },
        map,
        content: markerImg,
        zIndex: vesselId === object.vessel_id ? 700 : 200, //set zIndex less than hover vessel icon
      });
    }
  }
  return null;
};

export const updateOrRemoveAlarmMarker = (fleetAlarmData, object, marker, newPosition) => {
  const alarmData = fleetAlarmData.filter((f) => f.vesselId === object.vessel_id);
  if (alarmData?.length > 0) {
    const alarmHighestPriority = getAlarmHighestPriority(alarmData);
    if (alarmHighestPriority.alarmStatus === "ABNOR") {
      const img = marker.content;
      img.src = getMarkerData(alarmHighestPriority);
      marker.position = newPosition;
      return marker;
    } else {
      marker.map = null;
      return null;
    }
  }
  marker.map = null;
};
