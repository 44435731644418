import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";

import { useEngineLatestData } from "../../../monitoringPage/engine/useEngineLatestData";
import { useEngineHistoricalData } from "../../../monitoringPage/engine/useEngineHistoricalData";

import { checkIsNoData, checkIsShownLoadDiagram, engineSelect } from "../../../../util/monitoring/monitoring";

import LoadDiagram from "../../../monitoringPage/engine/loadDiagram/loadDiagram";
import ChartPowerCurve from "../../../monitoringPage/engine/powerCurve/powerCurve";
import { COLORS } from "../../../../constants/colors";
import localizationKeys from "../../../../i18n/localizationKeys";
import { calcBHP } from "../../../../util/monitoring/brakeHorsePowerCalc";

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "205px",
    "& #loadDiagramDiv, & #powerCurveDiv": {
      height: "150px !important",
      marginLeft: "-20px",
    },
  },
  titleContainer: {
    textAlign: "center",
    fontSize: "11px",
    color: COLORS.greenishBlue,
    fontWeight: "500",
  },
});

const EngineMonitoring = ({ vessel, style, classes, t }) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [noPowers, setnoPowers] = useState(false);
  const [engines, setEngines] = useState([]);
  const [enginePositions, setEnginePositions] = useState([]);
  const [fixEnginePositions, setFixEnginePositions] = useState([]);
  const [isNoData, setIsNoData] = useState(true);

  // const { vesselSettings } = useVesselSettings({ vesselId: vessel.id });
  const { latestNavigationData, latestEngineData, isLoading, isNoEngine } = useEngineLatestData({
    vesselId: vessel.id,
  });
  const { historicalNavigationData, historicalEngineData } =
    useEngineHistoricalData({
      vesselId: vessel.id,
    });

  const vesselBHP = calcBHP(vessel.id, latestEngineData);

  useEffect(() => {
    if (!isLoading) setIsInitialLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setIsInitialLoading(true);
    setEnginePositions([]);
  }, [vessel.id]);

  useEffect(() => {
    if (latestEngineData && vessel.NoME) {
      setnoPowers(checkIsShownLoadDiagram(vessel.NoME, latestEngineData, vesselBHP) || isNoEngine);
      setEngines(engineSelect(t, vessel.NoME));
      if (latestNavigationData) {
        const targetLatestNavigationData = latestNavigationData.filter(
          (value) => value.vessel_id === vessel.id
        );
        if (targetLatestNavigationData) {
          setIsNoData(checkIsNoData(vessel.NoME, latestEngineData, targetLatestNavigationData[0]));
        }
      }
    } else setEngines([]);
  }, [latestEngineData, latestNavigationData, vessel.NoME]);

  useEffect(() => {
    if (isInitialLoading) return;
    if (engines.length > 0) {
      if (enginePositions.length === 0) setEnginePositions(engines.map(engine => engine.number));
      setIsInitialLoading(false);
    } else setEnginePositions([]);
  }, [engines]);

  useEffect(() => {
    if (enginePositions.length > 0)
      setFixEnginePositions(
        engines.filter((d) => enginePositions.includes(d.number)).map((d) => d?.position)
      );
    else setFixEnginePositions([]);
  }, [enginePositions]);

  return !noPowers && !isNoData ? (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <Box className={ `${style.components} ${classes.container}` }>
          <div className={classes.titleContainer}>
            {t(localizationKeys.MainEngineLoad)}
          </div>
          <LoadDiagram
            vesselId={vessel.id}
            engineLatestData={latestEngineData}
            selectEngines={fixEnginePositions}
            isLoading={isLoading}
            isNoData={isNoData}
            isNoEngine={noPowers}
            isInitialLoading={isInitialLoading}
            vesselSettings={vessel}
            vesselBHP={vesselBHP}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={ `${style.components} ${classes.container}` }>
          <div className={classes.titleContainer}>
            {t(localizationKeys.MainEnginePowerCurve)}
          </div>
          <ChartPowerCurve
            vesselId={vessel.id}
            enginePositions={fixEnginePositions}
            engineLatestData={latestEngineData}
            navigationLatestData={latestNavigationData}
            isLoading={isLoading}
            isNoData={isNoData}
            isNoEngine={noPowers}
            isInitialLoading={isInitialLoading}
            engineHistoricalData={historicalEngineData}
            navigationHistoricalData={historicalNavigationData}
            vesselBHP={vesselBHP}
          />
        </Box>
      </Grid>
    </Grid>
  ) : <></>;
}

EngineMonitoring.propTypes = {
  vessel: PropTypes.object,
  style: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(EngineMonitoring);