import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { CircularProgress } from "@mui/material";
import * as PropTypes from "prop-types";
import { ENGINE_POSITION } from "../../../../constants/trendGraph/enginesLine";
import { currentVesselValue } from "../../../../model/slice/vesselSlice";
import Offline from "../../../offlineComponent/offline.jsx";
import { NoData } from "../../../offlineComponent/noData.jsx";
import { COLORS } from "../../../../constants/colors";

const MAX_SPEED = 30;

const ChartPowerCurve = ({
  enginePositions = [],
  engineLatestData = [],
  navigationLatestData = [],
  isLoading,
  isNoData,
  isNoEngine,
  isInitialLoading,
  engineHistoricalData = [],
  navigationHistoricalData = [],
  vesselBHP,
}) => {
  const [chartData, setChartData] = useState(null);
  const [chartDataH, setChartDataH] = useState(null);
  const meMaxKw = useSelector(currentVesselValue("meMaxKw", ""));
  am5.addLicense(process.env.AMCHARTS_LICENSE);
  const chartId = "powerCurveDiv";

  useEffect(() => {
    if (!engineLatestData?.length || !navigationLatestData?.length) return;
    setChartData(() => {
      return engineLatestData
        .filter((d) => enginePositions.includes(d.position))
        .map((d) => ({
          ...navigationLatestData[0]["data"][0],
          shaftHorsePower: d.shaftHorsePower,
          meOutput: d.meOutput,
          brakeHorsePower: vesselBHP,
          position: d.position,
        }));
    });
  }, [enginePositions, engineLatestData, navigationLatestData]);

  useEffect(() => {
    if (!engineHistoricalData?.length || !navigationHistoricalData?.length) return;
    setChartDataH(() => {
      const navigationHistoricalData_dict = navigationHistoricalData.reduce((acc, item) => {
        acc[item.dateTime.slice(0, 16)] = item;
        return acc;
      }, {});

      engineHistoricalData
        .filter((d) => enginePositions.includes(d.position))
        .forEach((item) => {
          if (item.dateTime.slice(0, 16) in navigationHistoricalData_dict) {
            navigationHistoricalData_dict[item.dateTime.slice(0, 16)]["shaftHorsePower"] =
              item.shaftHorsePower;
            navigationHistoricalData_dict[item.dateTime.slice(0, 16)]["position"] = item.position;
          }
        });
      return Object.values(navigationHistoricalData_dict);
    });
  }, [enginePositions, engineHistoricalData, navigationHistoricalData]);

  const makePowerCurve = () => {
    useEffect(() => {
      let newChartData = [],
        newChartDataH = [];
      if (chartData?.length > 0) {
        newChartData = chartData.map((v) => {
          v.position === ENGINE_POSITION.STARBOARD
            ? (v.fillRule = {
                fill: am5.color(COLORS.starBoardPink),
                stroke: am5.color(COLORS.starBoardPink),
              })
            : (v.fillRule = {
                fill: am5.color(COLORS.portYellow),
                stroke: am5.color(COLORS.portYellow),
              });
          return v;
        });
      }

      if (chartDataH?.length > 0) {
        newChartDataH = chartDataH;
      }

      const disPlayDataKey = chartData?.some((value) => value?.shaftHorsePower === null)
        ? (chartData?.some((value) => value?.meOutput === null) ? "brakeHorsePower" : "meOutput")
        : "shaftHorsePower";
      const root = am5.Root.new(chartId, {
        useSafeResolution: false,
      });
      root.setThemes([am5themes_Animated.new(root)]);
      const bubbleChart = root.container.children.push(am5xy.XYChart.new(root, {}));
      const xAxis = bubbleChart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: MAX_SPEED * 1.1,
          numberFormat: "#'knot'",
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 50,
          }),
        })
      );
      const xRenderer = xAxis.get("renderer");
      xRenderer.labels.template.setAll({
        fill: am5.color(COLORS.white),
        opacity: 0.2,
        fontSize: "14px",
        paddingTop: 14,
      });
      xRenderer.grid.template.setAll({
        stroke: am5.color(COLORS.white),
        strokeWidth: 2,
        strokeDasharray: [4, 2],
      });
      const yAxis = bubbleChart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: meMaxKw * 1.1,
          numberFormat: "#'kw'",
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      const yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.setAll({
        fill: am5.color(COLORS.white),
        opacity: 0.2,
        fontSize: "14px",
        paddingRight: 14,
      });
      yRenderer.grid.template.setAll({
        stroke: am5.color(COLORS.white),
        strokeWidth: 2,
        strokeDasharray: [4, 2],
      });

      const ReferenceLines = bubbleChart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "speedKnot",
          valueYField: "shaftHorsePower",
          legendLabelColor: am5.color("#ffffff"),
          legendLabelText: "[{legendLabelColor}]Reference Curve",
          stroke: COLORS.lightBlue,
          fill: COLORS.lightBlue,
        })
      );

      const HistoricalDataPoints = bubbleChart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "speedKnot",
          valueYField: "shaftHorsePower",
          legendLabelColor: am5.color("#ffffff"),
          legendLabelText: "[{legendLabelColor}]Historical Data",
        })
      );

      HistoricalDataPoints.bullets.push(() => {
        const graphics = am5.Circle.new(root, {
          radius: 2,
          stroke: am5.color("#ffffff"),
          fill: am5.color("#ffffff"),
          fillOpacity: 0.03,
          strokeOpacity: 0.03,
        });
        return am5.Bullet.new(root, {
          sprite: graphics,
        });
      });
      HistoricalDataPoints.strokes.template.set("strokeOpacity", 0);
      HistoricalDataPoints.data.setAll(newChartDataH);

      const k = meMaxKw / MAX_SPEED ** 3;
      ReferenceLines.data.setAll([
        { speedKnot: 0, shaftHorsePower: 0 },
        { speedKnot: MAX_SPEED / 2, shaftHorsePower: k * (MAX_SPEED / 2) ** 3 },
        {
          speedKnot: (MAX_SPEED * 4) / 5,
          shaftHorsePower: k * ((MAX_SPEED * 4) / 5) ** 3,
        },
        { speedKnot: MAX_SPEED, shaftHorsePower: meMaxKw },
      ]);

      /*const series = bubbleChart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "speedKnot",
          valueYField: disPlayDataKey,
          fill: am5.color(COLORS.white),
          maskBullets: false,
          tooltip: am5.Tooltip.new(root, {
            labelText: "[bold]speedKnot: {valueX} \n{valueYField}: {valueY}[/]",
          }),
        }),
      );
      const circleTemplate = am5.Template.new({});
      series.bullets.push(() => {
        const graphics = am5.Circle.new(
          root,
          {
            radius: 4,
            templateField: "fillRule",
          },
          circleTemplate,
        );
        return am5.Bullet.new(root, {
          sprite: graphics,
        });
      });
      series.strokes.template.set("strokeOpacity", 0);*/

      const seriesPort = am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis,
        yAxis,
        valueXField: "speedKnot",
        valueYField: disPlayDataKey,
        legendLabelColor: am5.color("#ffffff"),
        legendLabelText: "[{legendLabelColor}]Current Data P.",
        fill: am5.color(COLORS.white),
        maskBullets: false,
        tooltip: am5.Tooltip.new(root, {
          labelText: disPlayDataKey == "brakeHorsePower"
            ? "[bold]Port\nspeedKnot: {valueX} \nbrakeHorsePower: {valueY}[/]"
            : "[bold]Port\nspeedKnot: {valueX} \nshaftHorsePower: {valueY}[/]",
        }),
      });
      seriesPort.bullets.push(() => {
        const graphics = am5.Circle.new(root, {
          radius: 4,
          fill: am5.color(COLORS.portYellow),
          stroke: am5.color(COLORS.portYellow),
        });
        return am5.Bullet.new(root, {
          sprite: graphics,
        });
      });
      seriesPort.strokes.template.set("strokeOpacity", 0);

      const seriesStarboard = am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis,
        yAxis,
        valueXField: "speedKnot",
        valueYField: disPlayDataKey,
        legendLabelColor: am5.color("#ffffff"),
        legendLabelText: "[{legendLabelColor}]Current Data S.",
        fill: am5.color(COLORS.white),
        maskBullets: false,
        tooltip: am5.Tooltip.new(root, {
          labelText: disPlayDataKey == "brakeHorsePower"
            ? "[bold]Port\nspeedKnot: {valueX} \nbrakeHorsePower: {valueY}[/]"
            : "[bold]Port\nspeedKnot: {valueX} \nshaftHorsePower: {valueY}[/]",
        }),
      });
      seriesStarboard.bullets.push(() => {
        const graphics = am5.Circle.new(root, {
          radius: 4,
          fill: am5.color(COLORS.starBoardPink),
          stroke: am5.color(COLORS.starBoardPink),
        });
        return am5.Bullet.new(root, {
          sprite: graphics,
        });
      });
      seriesStarboard.strokes.template.set("strokeOpacity", 0);

      const seriesCenter = am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis,
        yAxis,
        valueXField: "speedKnot",
        valueYField: disPlayDataKey,
        legendLabelColor: am5.color("#ffffff"),
        legendLabelText: "[{legendLabelColor}]Current Data C.",
        fill: am5.color(COLORS.white),
        maskBullets: false,
        tooltip: am5.Tooltip.new(root, {
          labelText: disPlayDataKey == "brakeHorsePower"
            ? "[bold]Port\nspeedKnot: {valueX} \nbrakeHorsePower: {valueY}[/]"
            : "[bold]Port\nspeedKnot: {valueX} \nshaftHorsePower: {valueY}[/]",
        }),
      });
      seriesCenter.bullets.push(() => {
        const graphics = am5.Circle.new(root, {
          radius: 4,
          fill: am5.color(COLORS.centerBlue),
          stroke: am5.color(COLORS.centerBlue),
        });
        return am5.Bullet.new(root, {
          sprite: graphics,
        });
      });
      seriesCenter.strokes.template.set("strokeOpacity", 0);

      bubbleChart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis,
          yAxis,
          snapToSeries: [seriesPort, seriesStarboard, seriesCenter],
        })
      );
      const cursor = bubbleChart.get("cursor");
      cursor.lineX.setAll({
        stroke: am5.color(COLORS.white),
      });
      cursor.lineY.setAll({
        stroke: am5.color(COLORS.white),
      });
      //series.data.setAll(newChartData);

      newChartData.forEach((v) => {
        if (v.position === ENGINE_POSITION.PORT) {
          bubbleChart.series.push(seriesPort);
          seriesPort.data.setAll([v]);
        } else if (v.position === ENGINE_POSITION.STARBOARD) {
          bubbleChart.series.push(seriesStarboard);
          seriesStarboard.data.setAll([v]);
        } else {
          bubbleChart.series.push(seriesCenter);
          seriesCenter.data.setAll([v]);
        }
      });
      const legend = bubbleChart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(100),
          centerY: am5.percent(100),
          y: am5.percent(87),
          layout: root.verticalLayout,
          x: am5.percent(110),
        })
      );
      legend.data.setAll(bubbleChart.series.values);
    }, []);

    return <div id={chartId} style={{ height: "300px", width: "100%" }} />;
  };

  const Display = () => {
    switch (true) {
      case isInitialLoading:
        return <CircularProgress />;
      case isNoEngine:
        return <NoData />;
      case isNoData:
        return <Offline isEngine={true} />;
      case isLoading:
        return <CircularProgress style={{ margin: '0 auto' }} />;
      case chartData !== null:
        return makePowerCurve();
      default:
        return null;
    }
  };

  return <Display />;
};

ChartPowerCurve.propTypes = {
  vesselId: PropTypes.string,
  enginePositions: PropTypes.array.isRequired,
  engineLatestData: PropTypes.array,
  navigationLatestData: PropTypes.array,
  isLoading: PropTypes.bool,
  isNoData: PropTypes.bool,
  isNoEngine: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
};

export default React.memo(ChartPowerCurve);
