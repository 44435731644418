import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFleetLatestEngineData, fetchFleetLatestEngineDataByVesselId } from "../../api/fleet";

export const fetchFleetLatestEngineAsync = createAsyncThunk(
  "fleetEngine/asyncGet",
  async ({ companyId }) => {
    return await fetchFleetLatestEngineData(companyId);
  }
);

export const fetchFleetLatestEngineAsyncByVesselId = createAsyncThunk(
  "fleetEngine/asyncGetByVesselId",
  async ({ vesselId }) => {
    return await fetchFleetLatestEngineDataByVesselId(vesselId);
  }
);

export const fetchFleetLatestEngineFulfilled = (state, action) => {
  state.fleetLatestEngineData = action.payload;
};

export const fetchFleetLatestEngineByVesselIdFulfilled = (state, action) => {
  if (action.payload.length < 1) {
    return;
  }
  state.fleetLatestEngineData = action.payload;
};
