import { useEffect, useRef, useState } from "react";
import dayjs from 'util/dayjs-init.js';
import { SEP_CHART_TYPE } from "../../constants/trendGraph/sepLines";
import {
  AMOUNT_OF_CRANE,
  AMOUNT_OF_LEGS,
  AMOUNT_OF_THRUSTERS,
  filterCraneData,
  filterTrimHeelData,
} from "../../hooks/useSepTrendData";
import {
  fetchCraneData,
  fetchLegData,
  fetchThrusterData,
  fetchTrimAndHeelData,
} from "../../api/sepTrendData";
import { fetchVessel } from "../../api/vessel.js";
import * as Comlink from "comlink";
import LegDownloadWorker from "./worker/legDownload.worker";
import ThrusterDownloadWorker from "./worker/thrusterDownload.worker";
import { logger } from "../../api/logger";

const sortRow = (row) => {
  // Sort object key
  // 1. dateTime, 2. localDateTime, ....
  const tempRow = { ...row };
  tempRow.localDateTime = dayjs(tempRow.dateTime).tz("Asia/Tokyo").format();
  const items = Object.entries(tempRow)?.sort((a, b) => {
    switch (true) {
      case a[0] === "dateTime" && b[0] === "localDateTime":
        return -1;
      case a[0] === "localDateTime" && b[0] === "dateTime":
        return 1;
      case a[0] === "localDateTime":
        return -1;
      case a[0] === "dateTime":
        return -2;
      default:
        return 0;
    }
  });
  const returnObject = Object.assign(
    {},
    ...items.map((d) => ({
      [d[0]]: d[1],
    }))
  );
  returnObject.localDateTime = dayjs(returnObject.dateTime).tz("Asia/Tokyo").format();
  delete returnObject.id;
  return returnObject;
};

const getHeaders = (data) => {
  const csvHeader = [];
  data.forEach((d) => csvHeader.push(...Object.keys(sortRow(d))));
  return [...new Set(csvHeader)];
};

const getDefaults = (key) =>
  key === "dateTime" || key === "localDateTime" ? "2000-01-01T00:00:00.000Z" : "";

const generateDefaultValues = (headers) => {
  return Object.fromEntries(headers.map((h) => [h, getDefaults(h)]));
};

export const createCSV = async (data, formatter) => {
  return new Promise((resolve) => {
    logger.info("CSV Creation Started.");
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const csvHeader = getHeaders(data);
    const defaultValues = generateDefaultValues(csvHeader);
    const filteredRows = data.filter((d) => {
      const filtered = Object.keys(d)
        .filter((key) => !["localDateTime", "dateTime"].includes(key))
        .reduce((obj, key) => {
          obj[key] = d[key];
          return obj;
        }, {});
      return !Object.values(filtered).every((v) => [undefined, null].includes(v));
    });
    const csvRows = filteredRows
      .map((d) =>
        Object.values(Object.assign(JSON.parse(JSON.stringify(defaultValues)), sortRow(d))).join(
          formatter
        )
      )
      .join("\n");
    const csv = csvHeader.join(formatter) + "\n" + csvRows;
    logger.info("CSV Creation Completed.");
    resolve(new Blob([bom, csv], { type: "text/csv" }));
  });
};

export const createCsvDownload = ({ blob, filename }) => {
  const downloadLink = document.createElement("a");
  downloadLink.download = filename + ".csv";
  logger.info(`CSV Filename: ${filename}`);
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.dataset.downloadurl = ["text/csv", downloadLink.download, downloadLink.href].join(
    ":"
  );

  downloadLink.click();
};

const legDataProcess = async (legRawData) => {
  const legWorker = LegDownloadWorker();
  const legWrap = Comlink.wrap(legWorker);
  const legTrendData = await legWrap?.generateDownloadData({ legRawData });
  legWorker.terminate();
  return legTrendData;
};

const thrusterDataProcess = async (thrusterRawData) => {
  const thrusterWorker = ThrusterDownloadWorker();
  const thrusterWrap = Comlink.wrap(thrusterWorker);
  const thrusterTrendData = await thrusterWrap?.generateDownloadData({
    thrusterRawData,
  });
  thrusterWorker.terminate();
  return thrusterTrendData;
};

const addLocalDateTime = async (baseData) => {
  return new Promise((resolve) => {
    resolve(
      baseData.map((d) =>
        d?.dateTime ? { ...d, localDateTime: dayjs.utc(d?.dateTime).local().format() } : d
      )
    );
  });
};

const oneSecFetch = async ({ startDate, endDate, vesselId, sepChartType }) => {
  let amountLeg = AMOUNT_OF_LEGS;
  let amountThruster = AMOUNT_OF_THRUSTERS;
  let amountCrane = AMOUNT_OF_CRANE;
  const data = await fetchVessel(vesselId);
  if (data.vessel) {
    amountLeg = !data.vessel?.NoLeg ? AMOUNT_OF_LEGS : Number(data.vessel?.NoLeg);
    amountThruster = !data.vessel?.NoThruster
      ? AMOUNT_OF_THRUSTERS
      : Number(data.vessel?.NoThruster);
    amountCrane = !data.vessel?.NoCrane ? AMOUNT_OF_CRANE : Number(data.vessel?.NoCrane);
  }
  switch (sepChartType) {
    case SEP_CHART_TYPE.LEGS: {
      const legResults = await Promise.all(
        [...new Array(amountLeg)].map((d, i) =>
          fetchLegData(vesselId, i + 1, startDate, endDate, 0)
        )
      );
      return await legDataProcess(legResults);
    }
    case SEP_CHART_TYPE.THRUSTER: {
      const thrusterResults = await Promise.all(
        [...new Array(amountThruster)].map((d, i) =>
          fetchThrusterData(vesselId, startDate, endDate, i + 1, 0)
        )
      );
      return await thrusterDataProcess(thrusterResults);
    }
    case SEP_CHART_TYPE.CRANE1: {
      const craneResult = await Promise.all(
        [...new Array(amountCrane)].map((d, i) =>
          fetchCraneData(vesselId, i, startDate, endDate, 0)
        )
      );
      const craneMidData = await addLocalDateTime(craneResult.map((d) => d?.craneData).flat());
      const craneReturnData = filterCraneData(craneMidData, 1);
      return craneReturnData;
    }
    case SEP_CHART_TYPE.CRANE2: {
      const craneResult = await Promise.all(
        [...new Array(amountCrane)].map((d, i) =>
          fetchCraneData(vesselId, i, startDate, endDate, 0)
        )
      );
      const craneMidData = await addLocalDateTime(craneResult.map((d) => d?.craneData).flat());
      const craneReturnData = filterCraneData(craneMidData, 2);
      return craneReturnData;
    }
    case SEP_CHART_TYPE.TRIM_AND_HEEL: {
      const trimHeelResult = await fetchTrimAndHeelData(vesselId, startDate, endDate, 0);
      const midData = await addLocalDateTime(trimHeelResult?.trimAndHeelData);
      const result = filterTrimHeelData(midData);
      return result;
    }
  }
};

export const oneSecDownload = async (params) => {
  const { filename, setIsDownloading } = params;
  try {
    logger.info("One Second Fetch Started.");
    setIsDownloading(true);
    const result = await oneSecFetch(params);
    const csvData = await createCSV(result);
    createCsvDownload({ blob: csvData, filename });
    logger.info(`One Second Fetch Completed.`);
  } catch (e) {
    logger.error(`One Second Fetch Failed: ${e}`);
  } finally {
    setIsDownloading(false);
  }
};

export const useCsvDownload = ({ data, formatter = "," }) => {
  const [blob, setBlob] = useState(false);
  const unmount = useRef(false);

  const createBlob = async () => {
    const blobData = await createCSV(data, formatter);
    unmount.current || setBlob(blobData);
  };

  useEffect(() => {
    setBlob(false);
    if (!data || !data?.length) {
      return;
    }
    unmount.current || createBlob();
  }, [data]);

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  return {
    blob,
  };
};
