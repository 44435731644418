import React from "react";
import PropTypes from "prop-types";
import { TimePicker } from "@blueprintjs/datetime";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../constants/colors";
import dayjs from "util/dayjs-init.js";

import "../dateTimePicker/timePicker.css";

const useStyles = makeStyles({
  timePickerContainer: {
    display: "flex",
    background: COLORS.componentBackColor,
    justifyContent: "flex-end",
    paddingBottom: "10px",
  },
  startTimePicker: {
    position: "relative",
    right: "40%",
  },
  endTimePicker: {
    position: "relative",
    right: "20%",
  },
});

export const TimeRangePicker = (props) => {
  const { newStart, setNewStart, newEnd, setNewEnd } = props;
  const classes = useStyles();
  const utcOffset = dayjs().utcOffset();

  //Date object can't use UTC so use JST time -9 (note: display utc time but timezone is jst)
  const startDefaultValue = dayjs(newStart).subtract(utcOffset, "m").toDate();
  const endDefaultValue = dayjs(newEnd).subtract(utcOffset, "m").toDate();

  const onChangeHandler = (newTime, updateTarget, updateFunction) => {
    //fix newTime to correct utc time.
    const newUTCTime = dayjs(newTime).utc().add(utcOffset, "m");
    const newHour = newUTCTime.get("hour");
    const newMinutes = newUTCTime.get("minute");

    const returnValue = dayjs(updateTarget).utc().set("hour", newHour).set("minute", newMinutes);
    updateFunction(returnValue);
  };
  return (
    <div className={classes.timePickerContainer}>
      <TimePicker
        className={classes.startTimePicker}
        showArrowButtons
        value={startDefaultValue}
        onChange={(v) => onChangeHandler(v, newStart, setNewStart)}
      />
      <TimePicker
        className={classes.endTimePicker}
        showArrowButtons
        value={endDefaultValue}
        onChange={(v) => onChangeHandler(v, newEnd, setNewEnd)}
      />
    </div>
  );
};

TimeRangePicker.propTypes = {
  newStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  setNewStart: PropTypes.func,
  newEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  setNewEnd: PropTypes.func,
};
