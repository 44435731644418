import { axios } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session";

//This is JRCS company ID in master DB'.
//Remove this once iPortal has been finalized and admin role is already established.
const JRCS_ID = "09f08b32-1cbe-4b69-bf51-f3b8a742bc02";

export const fetchFleetLatestNavigationData = async (manager_id) => {
  //If user has no company, do not display anything in fleet
  if (!manager_id) {
    return;
  }

  //If user is from JRCS.. set owner id to null. In iAPI, null string for fleet means
  //user will be able to retrieve all ships regardless of company. So for JRCS users, we can see all ships of all owners
  if (manager_id === JRCS_ID) {
    manager_id = null;
  }

  try {
    const { data } = await axios.get(`/fleet/latestNavigationData`, {
      params: {
        manager_id,
      },
      headers: getAuthHeader(),
    });

    return { fleetLatestNavigationData: data };
  } catch (e) {
    throw new Error(e);
  }
};

export const fetchFleetLatestEngineData = async (manager_id) => {
  //If user has no company, do not display anything in fleet
  if (!manager_id) {
    return;
  }

  //If user is from JRCS.. set owner id to null. In iAPI, null string for fleet means
  //user will be able to retrieve all ships regardless of company. So for JRCS users, we can see all ships of all owners
  if (manager_id === JRCS_ID) {
    manager_id = null;
  }

  try {
    const { data } = await axios.get(`/fleet/latestEngineData`, {
      params: {
        manager_id,
      },
      headers: getAuthHeader(),
    });

    return { fleetLatestEngineData: data };
  } catch (e) {
    throw new Error(e);
  }
};

export const fetchFleetLatestEngineDataByVesselId = async (vessel_id) => {
  try {
    const { data } = await axios.get(`/fleet/latestEngineData`, {
      params: {
        vessel_id,
      },
      headers: getAuthHeader(),
    });

    return { fleetLatestEngineData: data };
  } catch (e) {
    throw new Error(e);
  }
};
