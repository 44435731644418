import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";

//Import required APIs

//Icon imports
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoOutlinedIcon from "../../../assets/images/main/icon-base-information.svg";

//Auth imports
import { COOKIE_AUTH_KEY } from "../../constants/constants.js";
import Cookies from "universal-cookie";
import { useMsal } from "@azure/msal-react";

//localizatoin
import localizationKeys from "../../i18n/localizationKeys.js";
import { NAVIGATION_BAR_HEIGHT } from "../../layout/panelLayout.jsx";
import { useMediaQuery, useTheme } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useOnClickOutside } from "../../hooks/useOnClickOutside.js";

const positionSetting = {
  position: "absolute",
  right: "17px",
  top: "0px",
};

const userSpace = {
  backgroundColor: "#a908bd",
  height: "30px",
  width: "30px",
  borderRadius: "50px",
  textAlign: "center",
  color: "#ffffff",
  cursor: "pointer",
  zIndex: "200",
};

const settingHolder = {
  padding: "18px 13px 18px 14px",
  backgroundColor: "rgba(2, 2, 2, 0.7)",
  position: "absolute",
  color: "#ffffff",
  fontSize: "12px",
  fontWeight: "bold",
  right: "0px",
};

const useStyles = makeStyles((theme) => ({
  settingHolder: {
    ...positionSetting,
    height: "100%",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  languageSelector: {
    backgroundColor: "rgba(2, 2, 2, 0.7)",
    height: "30px",
    width: "30px",
    lineHeight: "32px",
    borderRadius: "50px",
    textAlign: "center",
    color: "#ffffff",
    fontSize: "12px",
    fontWeight: "300",
    cursor: "pointer",
    zIndex: "200",
    "& p": {
      marginTop: "-3px",
    },
  },
  userSelector: {
    position: "relative",
    top: "12%",
  },
  userSpace: {
    ...userSpace,
    position: "relative",
  },
  mobileUserSpace: {
    ...userSpace,
    marginRight: "5px",
  },
  userSettingHolder: {
    ...settingHolder,
    top: `${NAVIGATION_BAR_HEIGHT}px`,
    width: "250px",
    // marginTop:  `20px`,
    // marginTop: `${100 + NAVIGATION_BAR_HEIGHT}px`,
    // marginRight: "-20px"
  },
  languageSettingHolder: {
    ...settingHolder,
    top: `${NAVIGATION_BAR_HEIGHT}px`,
    width: "200px",
  },
  languageSettingHolderSm: {
    ...settingHolder,
    top: `${NAVIGATION_BAR_HEIGHT}px`,
    right: "28px",
    width: "55px",
  },
  settingRow: {
    height: "35px",
    cursor: "pointer",
  },
  settingIcons: {
    paddingRight: "10px",
  },
  selectedLanguage: {
    border: "solid 2px #b900c4",
    height: "32px",
    width: "32px",
  },
  languageIcon: {
    border: "solid 2px #ffffff",
    height: "28px",
    width: "28px",
  },
  languageIconSelected: {
    border: "solid 2px #b900c4",
    height: "28px",
    width: "28px",
  },
  menuIconHolder: {
    ...positionSetting,
    marginRight: "-10px",
  },
  menuList: {
    "& .MuiPaper-root": {
      backgroundColor: "rgba(44, 58, 72, 0.7)",
      top: "50px!important",
      right: "0px!important",
      left: "auto!important",
    },
  },
  allowIconPosition: {
    float: "right",
    height: "24px",
    verticalAlign: "middle",
    display: "inline-block",
  },
  settingCell: {
    verticalAlign: "middle",
    display: "table-cell",
  },
  settingCellContent: {
    display: "inline-block",
    height: "17px",
    verticalAlign: "middle",
    marginTop: "2px",
  },
  settingsTable: {
    width: "230px",
  },
}));

const UserSettings = (props) => {
  const { instance } = useMsal();
  const { t } = props;
  const classes = useStyles();
  const [isDisplaySettings, setIsDisplaySettings] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState(props.i18n.language);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDisplayLanguageSettings, setIsDisplayLanguageSettings] = React.useState(false);
  const [isDisplayLanguageSettingsSm, setIsDisplayLanguageSettingsSm] = React.useState(false);
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);
  const navigate = useNavigate();
  const languageRef = useRef(null);
  const languageRefSm = useRef(null);

  // if user click out size of user setting menu, menu will be closed.
  useOnClickOutside([menuRef, menuButtonRef, languageRef], () => {
    hideDropdowns();
  });

  useOnClickOutside([languageRefSm], () => {
    setIsDisplayLanguageSettingsSm(false);
  });

  const logout = () => {
    props.setUserContext(null);
    const cookies = new Cookies();
    cookies.remove(COOKIE_AUTH_KEY);

    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const servicePolicy = () => {
    hideDropdowns();
    navigate("/servicepolicy");
  };

  const hideDropdowns = () => {
    setIsDisplaySettings(false);
    setIsDisplayLanguageSettings(false);
  };

  const SelectedLanguageIcon = ({ selectedLanguage, selected = false }) => {
    const language = () => {
      if (selectedLanguage === "ja") return "JP";
      else if (selectedLanguage === "en-US") return "EN";
      else return "";
    };
    return (
      <div
        className={`${classes.languageSelector} ${
          selected ? classes.languageIconSelected : classes.languageIcon
        }`}
      >
        <p>{language()}</p>
      </div>
    );
  };

  const displayUserSettings = () => {
    const languageSettingsOnClick = () => {
      setIsDisplayLanguageSettings(true);
      setIsDisplaySettings(false);
    };

    const languageBackOnClick = () => {
      setIsDisplayLanguageSettings(false);
      setIsDisplaySettings(true);
    };

    if (isDisplaySettings) {
      return (
        <div className={classes.userSettingHolder} ref={menuRef}>
          <table className={classes.settingsTable}>
            <tbody>
              <tr className={classes.settingRow} onClick={logout}>
                <td className={classes.settingIcons}>
                  <ExitToAppIcon />
                </td>
                <td> {t(localizationKeys.Logout_lower)}</td>
              </tr>
              {/* <tr  className={classes.settingRow}>
                            <td className={classes.settingIcons}><SettingsIcon /></td>
                            <td> {t(localizationKeys.Settings_lower)}</td>
                        </tr> */}
              {isXs ? (
                <tr className={classes.settingRow} onClick={() => languageSettingsOnClick()}>
                  <td className={classes.settingIcons}>
                    <SelectedLanguageIcon selectedLanguage={selectedLanguage} />
                  </td>
                  <td className={classes.settingCell}>
                    <div className={classes.settingCellContent}>
                      {t(localizationKeys.SelectedLanguage_lower)}
                    </div>
                    <div className={classes.allowIconPosition}>
                      <ArrowForwardIos />
                    </div>
                  </td>
                </tr>
              ) : null}
              {/*<tr className={classes.settingRow} onClick={getInfoPage}>*/}
              {/*  <td className={classes.settingIcons}><img src={InfoOutlinedIcon} alt="information icon"/></td>*/}
              {/*  <td> {t(localizationKeys.ProductInformation_lower)}</td>*/}
              {/*</tr>*/}
              <tr className={classes.settingRow} onClick={() => servicePolicy()}>
                <td className={classes.settingIcons}>
                  <img src={InfoOutlinedIcon} alt="information icon" />
                </td>
                <td> {t(localizationKeys.ServicePolicyLink_lower)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else if (isDisplayLanguageSettings) {
      return (
        <div className={classes.languageSettingHolder} ref={languageRef}>
          <table>
            <tbody>
              <tr className={classes.settingRow} onClick={languageBackOnClick}>
                <td className={classes.settingIcons}>
                  <ArrowBackIos />
                </td>
                <td> {t(localizationKeys.BackMenu_lower)}</td>
              </tr>
              <tr
                className={classes.settingRow}
                onClick={() => {
                  props.changeLanguage("en-US");
                  hideDropdowns();
                }}
              >
                <td className={classes.settingIcons}>
                  <SelectedLanguageIcon
                    selectedLanguage={"en-US"}
                    selected={selectedLanguage === "en-US"}
                  />
                </td>
                <td>English</td>
              </tr>
              <tr
                className={classes.settingRow}
                onClick={() => {
                  props.changeLanguage("ja");
                  hideDropdowns();
                }}
              >
                <td className={classes.settingIcons}>
                  <SelectedLanguageIcon
                    selectedLanguage={"ja"}
                    selected={selectedLanguage === "ja"}
                  />
                </td>
                <td>日本語</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    setSelectedLanguage(props.i18n.language);
  }, [props.i18n.language]);

  const defaultDisplayMenu = () => (
    <>
      <div className={classes.settingHolder}>
        <div
          className={`${classes.languageSelector} ${
            selectedLanguage === "en-US" ? `${classes.selectedLanguage}` : ""
          }`}
          onClick={() => {
            props.changeLanguage("en-US");
          }}
        >
          EN
        </div>
        <div
          className={`${classes.languageSelector} ${
            selectedLanguage === "ja" ? `${classes.selectedLanguage}` : ""
          }`}
          onClick={() => {
            props.changeLanguage("ja");
          }}
        >
          JP
        </div>
        <div
          ref={menuButtonRef}
          className={classes.userSpace}
          onClick={() => setIsDisplaySettings(!isDisplaySettings)}
        >
          <PersonIcon className={classes.userSelector} />
        </div>
      </div>
      {isDisplaySettings === true ? displayUserSettings() : ""}
    </>
  );

  const displayMenuSm = () => (
    <>
      <div className={classes.settingHolder}>
        <div
          ref={languageRefSm}
          onClick={() => setIsDisplayLanguageSettingsSm(!isDisplayLanguageSettingsSm)}
        >
          <SelectedLanguageIcon selectedLanguage={selectedLanguage} selected={true} />
          {isDisplayLanguageSettingsSm ? displayLanguageDropdown() : ""}
        </div>
        <div
          ref={menuButtonRef}
          className={classes.userSpace}
          onClick={() => setIsDisplaySettings(!isDisplaySettings)}
        >
          <PersonIcon className={classes.userSelector} />
        </div>
      </div>
      {isDisplaySettings ? displayUserSettings() : ""}
    </>
  );

  const displayLanguageDropdown = () => {
    const lang = selectedLanguage === "ja" ? "en-US" : "ja";
    return (
      <div className={classes.languageSettingHolderSm}>
        <div
          onClick={() => {
            props.changeLanguage(lang);
            setIsDisplayLanguageSettingsSm(false);
          }}
        >
          <SelectedLanguageIcon selectedLanguage={lang} selected={false} />
        </div>
      </div>
    );
  };

  const clickedMobileUserSettings = () => {
    switch (true) {
      case isDisplaySettings:
        setIsDisplaySettings(false);
        setIsDisplayLanguageSettings(false);
        break;
      case isDisplayLanguageSettings:
        setIsDisplaySettings(false);
        setIsDisplayLanguageSettings(false);
        break;
      default:
        setIsDisplaySettings(true);
        setIsDisplayLanguageSettings(false);
    }
  };

  const displayMenu = () => {
    switch (true) {
      case isXs:
        return (
          <div
            className={classes.mobileUserSpace}
            ref={menuButtonRef}
            onClick={() => {
              clickedMobileUserSettings();
            }}
          >
            <PersonIcon className={classes.userSelector} />
          </div>
        );
      case isSm:
        return displayMenuSm();
      default:
        return defaultDisplayMenu();
    }
  };

  return (
    <React.Fragment>
      {displayMenu()}
      {displayUserSettings()}
    </React.Fragment>
  );
};

UserSettings.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  setUserContext: PropTypes.func.isRequired,
};

export default withTranslation()(UserSettings);
