import React, { memo } from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import ArrowIcon from "../../../../../assets/images/cargo/load/arrow.svg";
import { COLORS } from "../../../../constants/colors";
import localizationKeys from "../../../../i18n/localizationKeys";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    width: "100px",
    height: "100px",
    margin: "0 auto",
    padding: "0",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.white,
  },
  chart: {
    position: "absolute",
    width: "80px",
    height: "80px",
    borderRadius: "100%",
    transform: "rotate(-135deg)",
  },
  hole: {
    position: "absolute",
    backgroundColor: COLORS.tabSelectorColor,
    width: "63px",
    height: "63px",
    borderRadius: "50%",
  },
  overviewHole: {
    backgroundColor: `${COLORS.componentBackColor} !important`,
  },
  valueContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99,
  },
  value: {
    fontSize: "16px",
  },
  unit: {
    fontSize: "10px",
  },
  labelContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    zIndex: 99,
    fontSize: "10px",
    gap: "30px",
    bottom: "7px",
  },
  arrow: {
    position: "absolute",
    width: "110px",
    height: "110px",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
    margin: "-5px",
  },
}));

export const GaugeIcon = memo((props) => {
  const { gauge, value, scale, isVesselOverview } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isUnexpectedValueForDisplay = (val) => {
    if (val === undefined || val === null || isNaN(val)) {
      return true;
    } else {
      return false;
    }
  };

  const checkUnexpectedValueForGauge = (val) => {
    const value = Number(val);
    if (value < gauge.measureRangeMin) {
      return gauge.measureRangeMin;
    } else if (gauge.measureRangeMin <= value && value <= gauge.measureRangeMax) {
      return value;
    } else if (value > gauge.measureRangeMax) {
      return gauge.measureRangeMax;
    } else {
      return gauge.measureRangeMin;
    }
  };

  const getDegree = (val) => {
    const checkedValue = checkUnexpectedValueForGauge(val);
    return (
      270 *
      ((checkedValue - gauge.measureRangeMin) / (gauge.measureRangeMax - gauge.measureRangeMin))
    );
  };

  const low1 = getDegree(gauge.low1);
  const low2 = getDegree(gauge.low2);
  const low3 = getDegree(gauge.low3);
  const high1 = getDegree(gauge.high1);
  const high2 = getDegree(gauge.high2);
  const high3 = getDegree(gauge.high3);
  const degree = getDegree(value);

  const GaugeThresholdTooltip = styled('div')({
    position: "absolute",
    display: "block",
    right: 0,
    left: 0,
    bottom: "35px",
    width: "5px",
    height: "10px",
    transformOrigin: "0px 45px",
    zIndex: "500",
  });
  const allThresholdDegrees = [[low1, gauge.low1], [low2, gauge.low2], [low3, gauge.low3], [high1, gauge.high1], [high2, gauge.high2], [high3, gauge.high3]];
  const thresholdHoverTargets = allThresholdDegrees.map((tup) => {
    const [deg, lvl] = tup;
    return (
    
      <Tooltip title={lvl + " " + gauge?.unit} disableInteractive sx={{
        zIndex: 9999,
        }}>
        <GaugeThresholdTooltip style={{
          transform: `rotate(${deg-135}deg) translate(-2.5px, 4px)`,
        }}>
      </GaugeThresholdTooltip>
    </Tooltip>);
  }); 

  return (
    <div className={classes.container} style={{ transform: `scale(${scale})` }}>
      <div
        className={classes.chart}
        style={{
          background: `conic-gradient(
          #e53f3f 0deg ${low1 - 1}deg,
          transparent ${low1 - 1}deg ${low1 + 1}deg,
          #ebd300 ${low1 + 1}deg ${low2 - 1}deg,
          transparent ${low2 - 1}deg ${low2 + 1}deg,
          #ebd300 ${low2 + 1}deg ${low3 - 1}deg,
          transparent ${low3 - 1}deg ${low3 + 1}deg,
          #059000 ${low3 + 1}deg ${high1 - 1}deg,
          transparent ${high1 - 1}deg ${high1 + 1}deg,
          #ebd300 ${high1 + 1}deg ${high2 - 1}deg,
          transparent ${high2 - 1}deg ${high2 + 1}deg,
          #ebd300 ${high2 + 1}deg ${high3 - 1}deg,
          transparent ${high3 - 1}deg ${high3 + 1}deg,
          #e53f3f ${high3 + 1}deg 270deg,
          transparent 270deg 360deg
        )`,
          opacity: isUnexpectedValueForDisplay(value) ? 0.5 : 1,
        }}
      />
      <div className={`${classes.hole} ${isVesselOverview && classes.overviewHole}`} />
      <div style={{position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%", zIndex: "200"}}>
        {thresholdHoverTargets}
      </div>

      <div className={classes.valueContainer}>
        <div className={classes.value}>
          {isUnexpectedValueForDisplay(value)
            ? t(localizationKeys.NoLatestDataForChart)
            : Number(value).toFixed(2)}
        </div>
        {!isUnexpectedValueForDisplay(value) && <div className={classes.unit}>{gauge?.unit}</div>}
      </div>
      <div
        className={classes.labelContainer}
        style={{ opacity: isUnexpectedValueForDisplay(value) ? 0.5 : 1 }}
      >
        <div className={classes.minLabel}>{gauge.measureRangeMin.toFixed(1)}</div>
        <div className={classes.maxLabel}>{gauge.measureRangeMax.toFixed(1)}</div>
      </div>
      <div
        className={classes.arrow}
        style={{
          transform: `rotate(${-135 + degree}deg)`,
        }}
      >
        <img src={ArrowIcon} width={25} height={25} style={{ transform: "rotate(180deg)" }} />
      </div>
    </div>
  );
});

GaugeIcon.propTypes = {
  gauge: PropTypes.object,
  value: PropTypes.string,
  scale: PropTypes.number,
  isVesselOverview: PropTypes.bool,
};
