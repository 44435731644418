import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from "../../constants/colors.js";
import localizationKeys from "../../i18n/localizationKeys.js";
import Thumbnail from '../navigationPage/thumbnail.jsx';
import { colorFromRank, SyntheticaStyles } from "./styles.jsx";

import { VesselFlagShortCode } from "../vesselImages/vesselFlag.js";

export const VesselCard = (props) => {
    const { data, vesselId, speedOverGround, countryCode } = props;
    const { t } = useTranslation();

    const styles = {
        flagHolder: { 
            position: "absolute", 
            top: 0, right: 0, 
            transform: "translate(50%,-50%)", 
            width: "22px", 
            height: "22px" 
        },
        flagText: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "8px",
        },
        flagImage: {
            filter: "brightness(50%)",
            width: "100%",
            height: "100%",
        },
    }
    const currentRatingColor = colorFromRank(data.vesselStatus.rating_value);
    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ ...SyntheticaStyles.paperHeader, textAlign: "left" }}>{t(localizationKeys.VesselDetails)}</Box>
            <Grid container spacing={1} padding={0} xs={12} sx={{ flexGrow: "1" }}>
                <Grid xs={5} sx={{ textAlign: "left", height: "100%", position: "relative" }}>
                    <Box sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
                        <Thumbnail vesselId={vesselId} />
                        <Box sx={styles.flagHolder}>
                            <Box component="img" sx={styles.flagImage} src={VesselFlagShortCode(countryCode)} />
                            <Box sx={styles.flagText}>{countryCode}</Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid container xs={7}>
                    <Grid xs={7}>
                        <div style={{ fontSize: "32px", fontWeight: "bold", textTransform: "uppercase" }}>{data.vesselPerformance.vessel_name}</div>
                        <div style={{ fontSize: "12px", fontWeight: 500, color: COLORS.white70 }}>PASSENGER</div>
                    </Grid>
                    <Grid xs={5}>
                        <div style={{ fontSize: "32px", fontWeight: "bold", textTransform: "uppercase", color: currentRatingColor }}>{data.vesselStatus.rating_value}</div>
                        <div style={{ fontSize: "12px", fontWeight: "500", color: COLORS.white70 }}>{t(localizationKeys.Rank).toUpperCase()}</div>
                    </Grid>
                    <Grid xs={7}>
                        <div style={{ fontSize: "10px", color: COLORS.white70 }}>{t(localizationKeys.VesselSpeedOG)}</div>
                        <div style={{ fontSize: "12px", color: COLORS.white }}>{speedOverGround} knot</div>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    );
}