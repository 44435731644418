import { bhpCalcDict } from "../../constants/monitoring/bhpCalc"
import { useChannelLatestData } from "../../components/monitoringPage/engine/useChannelLatestData";

export const calcBHP = (vesselId, engineData) => {
  let bhp = 0;
  const { latestChannelData, isLoadingC } = useChannelLatestData({
    vesselId
  });
  if (!Object.keys(bhpCalcDict).includes(vesselId)) return -1;
  if (latestChannelData) {
    const channels = latestChannelData.filter((d) => Object.keys(bhpCalcDict[vesselId]).includes(d.chNo))
    .map((d) => ({
        chNo: d.chNo,
        value: Number(d.inputData)
    }));

    if (channels.length) bhp += channels.reduce((d) => ((d.value)*bhpCalcDict[vesselId][d.chNo]));
    if (engineData != undefined && engineData.length) {
      bhp += engineData.filter((d) => d.position == bhpCalcDict[vesselId].enginePosition)[0].meSpeed * bhpCalcDict[vesselId]["rpm"];
      return bhp;
    }
  }

  return 0;
}