import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useForm, useWatch } from 'react-hook-form';
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  IconButton,
} from "@mui/material";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { TableRowStyled, TableCellStyled } from "./tableStyled.jsx";
import { RemoveIcon } from "./removeIcon.jsx";
import {
  CHART_PROMPTS,
  DEFAULT_CHART_TITLE,
  MAX_CHANNEL_COUNT,
} from "../../../constants/trendGraph/charts";
import { updateCharts, deleteChart } from "../../../api/charts";
import { Confirm } from "./modal/confirm.jsx";
import DeleteIcon from "../icon/deleteIcon.jsx";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType.js";
import { mockData } from "./data/mockData.js";
import { DEFAULT_LINE_COLORS } from "../../../constants/trendGraph/linesDefaultColors.js";
import Chart from "../../chart/chart.jsx";
import { useNavigate } from "react-router-dom";

import PencilIcon from "../../../../assets/images/custom/pencil-alt.svg";
import { COLORS } from "../../../constants/colors.js";

const submitButton = {
  width: "172px",
  height: "43px",
  borderRadius: "5px",
  fontWeight: "600",
  backgroundColor: "#19b2ab !important",
  color: "#21262f !important",
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    "& input": {
      color: "#ffffff",
      background: "rgba(1, 1, 1, 0)",
      border: 0,
    },
  },
  chartTitle: {
    position: "relative",
    "& input": {
      padding: "5px 41px 9px",
      minWidth: "50px",
      width: "calc(100%)",
      "&:hover": {
        padding: "4px 40px 8px",
        borderRadius: "4px",
        border: "solid 1px rgba(156, 156, 156, 0.5)",
      },
      "&:focus": {
        padding: "4px 40px 8px",
        borderRadius: "4px",
        border: "solid 1px #9c9c9c",
        backgroundColor: "rgba(44, 58, 72, 0.3)",
      },
    },
    "&:hover": {
      "& input": {
        padding: "4px 40px 8px",
        borderRadius: "4px",
        border: "solid 1px rgba(156, 156, 156, 0.5)",
      },
    },
  },
  titleIcon: {
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "-5px",
    left: "0px",
  },  
  chartSubtitle: {
    fontSize: "16px",
    border: "1px solid #9c9c9c",
    backgroundColor: "rgba(44, 58, 72, 0.3)",
    height: "43px",
    width: "400px",
    display: "flex",
    "& div": {
      lineHeight: "40px",
      textAlign: "center",
      "&:nth-child(1)": {
        minWidth: "40px",
        fontSize: "12px",
        borderRight: "1px solid #9c9c9c",
      },
      "&:nth-child(2) input": {
        padding: "0 10px",
        height: "40px",
      },
    },
  },
  body: {
    marginTop: "50px",
  },
  searchControl: {
    fontSize: "10px",
  },
  searchInput: {
    width: "300px",
    height: "26px",
    padding: "8px",
    marginTop: "5px",
    borderRadius: "2px",
    backgroundColor: "#2c3a48 !important",
  },
  showAll: {
    cursor: "pointer",
    marginTop: "8px",
    color: "#19b2ab",
  },
  tableContainer: {
    marginTop: "32px",
  },
  tableMessage: {
    fontSize: "12px",
    whiteSpace: "pre-wrap",
  },
  noMatchFound: {
    width: "100vw",
    height: "535px",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  table: {
    height: "562px",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: `${COLORS.backgroundColor} !important`,
    },
    "& .MuiTable-stickyHeader": {
      "border-collapse": "collapse !important",
    },
    "& input[type='checkbox']": {
      appearance: "none",
      margin: 0,
      font: "inherit",
      width: "20px",
      height: "20px",
      border: "0.15em solid #19b2ab",
      borderRadius: "0.15em",
      transform: "translateY(3px)",
    },
    "& input[type='checkbox']:checked": {
      "accent-color": "#19b2ab",
      appearance: "auto",
    },
    "&::after": {
      content: "abcdefg",
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      height: "100px",
      width: "100%",
      backgroundImage: `linear-gradient(to right, rgba(255,255,255,0), ${COLORS.backgroundColor})`,
      zIndex: 9999,
      "pointer-events": "none",
    },
  },
  disabledRow: {
    opacity: "0.5",
    "& input[type='checkbox']": {
      border: "0.15em solid #ffffff",
    },
  },
  headerHolder: {
    height: "38px",
    "& .MuiTableCell-root": {
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
    },
  },
  selectedRow: {
    backgroundColor: "rgba(25, 178, 171, 0.2)",
  },
  unSelectedRow: {
    backgroundColor: "none",
  },
  graphHeader: {
    fontSize: "16px",
  },
  graphSubHeader: {
    marginLeft: "10px",
    fontSize: "10px",
    opacity: "0.7",
  },
  graph: {
    height: "220px",
    marginTop: "20px",
  },
  linesContainer: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  lines: {
    height: "320px",
    marginTop: "20px",
  },
  line: {
    display: "inline-flex",
    border: "1px solid rgba(25, 178, 171, 0.7)",
    width: "fit-content",
    padding: "6px 6px 6px 10px",
    margin: "0 8px 8px 0",
    borderRadius: "10px",
    backgroundColor: "#252d39",
    fontSize: "10px",
    height: "32px",
  },
  lineTitle: {
    height: "20px",
    verticalAlign: "middle",
    paddingTop: "2px",
  },
  lineIcon: {
    height: "20px",
    verticalAlign: "middle",
    marginLeft: "8px",
  },
  controller: {
    display: "flex",
  },
  controllerLeft: {
    "flex-grow": 1,
  },
  controllerRight: {},
  submitButton: {
    ...submitButton,
  },
  disabledSubmitButton: {
    ...submitButton,
    opacity: "0.5",
  },
  cancelButton: {
    marginLeft: "40px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#19b2ab",
    cursor: "pointer",
  },
  deleteButton: {
    marginLeft: "8px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#ff5252",
  },
  customChartDisclaimer: {
    fontSize: "10px",
    opacity: "0.7",
    textAlign: "center"
  }
}));

const CustomGraph = (props) => {
  const {
    vesselId,
    customChartTitle = useWatch({ control, name: 'title' }),
    setCustomChartTitle,
    setIsCustomChartsCreated,
    setIsCreateCustomCharts,
    setIsChartDeleted,
    customCharts,
    selectedChartData,
    channels,
    classes,
    i18n,
    t,
  } = props;
  const styles = useStyles();
  const navigate = useNavigate();
  const { register, setValue, handleSubmit, reset, control } = useForm({
    defaultValues: {
      title: customChartTitle,
    },
    mode: "onBlur",
  });
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptContent, setPromptContent] = useState({});
  const [subtitle, setSubtitle] = useState("");

  const language = useCallback(() => {
    if (i18n.language === "ja") return "EN";
    else if (i18n.language === "en-US") return "JP";
    else return "";
  }, [i18n.language]);

  const chartLines = useMemo(() => {
    return (
      selectedChannels?.map((line, idx) => {
        return {
          key: `"${line}"`,
          keyName: `"${line}"`,
          name: `"${line}"`,
          color: DEFAULT_LINE_COLORS[idx],
          enableVisibilityIcon: "hidden",
          enable: true,
        };
      }) ?? null
    );
  }, [selectedChannels]);

  const mockValues = useMemo(() => {
    const data = [];
    mockData.forEach((d) => {
      const chartData = {};
      chartLines.forEach((line, idx) => (chartData[line.key] = d[idx + 1]));
      data.push({
        dateTime: d.dateTime,
        ...chartData,
      });
    });
    return data;
  }, [chartLines]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  useEffect(() => {
    if (channels?.length > 0) {
      if (selectedChartData) {
        setValue("title", selectedChartData.name);
        setValue("subtitle", selectedChartData.otherName);
        setCustomChartTitle(selectedChartData.name);
        setSubtitle(selectedChartData.otherName);
        const sel = selectedChartData.lines.map((ch) => {
          channels.find((c) => c.chNo === ch.chNo).selected = true;
          return ch.chNo;
        });
        setSelectedChannels(sel);
      } else {
        resetForm();
      }
    }
  }, [channels, selectedChartData]);

  const updateSelectedChannels = (value, isChecked) => {
    const ch = channels.find((c) => c.chNo === value);
    if (isChecked) {
      setSelectedChannels([...new Set([...selectedChannels, value])]);
      ch.selected = true;
    } else {
      setSelectedChannels([...selectedChannels.filter((ch) => ch !== value)]);
      ch.selected = false;
      reset({
        selectedChannels: [...selectedChannels.filter((ch) => ch !== value)],
      });
    }
  };

  const isSubmitDisabled = useMemo(() => {
    const selectedChannelsLength = selectedChannels?.length ?? 0;
    return selectedChannelsLength === 0;
  });

  const updateSearchValue = (value) => {
    setSearchValue(value);
    value = value?.toLowerCase();
    if (value) {
      setFilteredChannels(
        channels.filter(
          (ch) => ch.chName.toLowerCase().includes(value) || ch.chNo.toString().includes(value)
        )
      );
    } else {
      setFilteredChannels([]);
    }
  };

  const resetForm = () => {
    setCustomChartTitle(DEFAULT_CHART_TITLE);
    setSubtitle("");
    setSearchValue("");
    setFilteredChannels([]);
    setSelectedChannels([]);
    reset({
      title: DEFAULT_CHART_TITLE,
      subtitle: "",
      selectedChannels: [],
    });
    channels.map((ch) => (ch.selected = false));
  };

  const saveChart = async (data) => {
    const req = {
      id: selectedChartData?.id,
      vesselId,
      name: data.title,
      otherName: data.subtitle,
      position: (customCharts?.length ?? 0) + 1,
      lines: selectedChannels.map((ch) => ({
        chNo: ch,
      })),
    };

    await updateCharts(req);
    setIsCustomChartsCreated(true);
  };

  const showAll = () => {
    setSearchValue("");
    setFilteredChannels([...channels]);
  };

  const onCancel = () => {
    const okHandler = () => {
      if (selectedChartData) {
        setIsCreateCustomCharts(false);
      } else if (customCharts?.length > 0) {
        resetForm();
        navigate(`/trend/custom/${customCharts[0].id}/${vesselId}`);
      } else {
        resetForm();
      }
      setShowPrompt(false);
    };

    const cancelHandler = () => {
      setShowPrompt(false);
    };

    setPromptContent({ ...CHART_PROMPTS.CANCEL, okHandler, cancelHandler });
    setShowPrompt(true);
  };

  const onSubmit = async (data) => {
    const submitData = data;

    const okHandler = () => {
      saveChart(submitData);
      setShowPrompt(false);
    };

    const cancelHandler = () => {
      setShowPrompt(false);
    };

    setPromptContent({ ...CHART_PROMPTS.SAVE, okHandler, cancelHandler });
    setShowPrompt(true);
  };

  const onDelete = async () => {
    const okHandler = async () => {
      await deleteChart(vesselId, selectedChartData.id);
      setIsChartDeleted(true);
      setShowPrompt(false);
    };

    const cancelHandler = () => {
      setShowPrompt(false);
    };

    setPromptContent({
      ...CHART_PROMPTS.DELETE,
      okHandler,
      cancelHandler,
      isDelete: true,
    });
    setShowPrompt(true);
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow className={classes.headerHolder}>
        <TableCellStyled key={`custom-header-1`} style={{ width: "100px" }}>
          {t(localizationKeys.CustomChannelNo)}
        </TableCellStyled>
        <TableCellStyled key={`custom-header-2`}>
          {t(localizationKeys.CustomChannelName)}
        </TableCellStyled>
        <TableCellStyled key={`custom-header-3`} style={{ width: "200px" }}>
          {t(localizationKeys.CustomUnit)}
        </TableCellStyled>
        <TableCellStyled key={`custom-header-4`} style={{ width: "50px" }} />
      </TableRow>
    </TableHead>
  );

  const TableCustBody = () => (
    <TableBody>
      {filteredChannels.map((channel) => (
        <TableRowStyled
          key={channel.chNo}
          className={`${channel.selected ? styles.selectedRow : ""} ${
            selectedChannels.length >= MAX_CHANNEL_COUNT && !channel.selected
              ? styles.disabledRow
              : ""
          }`}
        >
          <TableCellStyled component="th" scope="row">
            {channel.chNo}
          </TableCellStyled>
          <TableCellStyled align="left">{channel.chName}</TableCellStyled>
          <TableCellStyled align="left">{channel.unit}</TableCellStyled>
          <TableCellStyled align="right">
            <input
              type="checkbox"
              id={channel.chNo}
              value={channel.chNo}
              checked={channel.selected}
              disabled={selectedChannels.length >= MAX_CHANNEL_COUNT && !channel.selected}
              {...register("selectedChannels", {
                onChange: (event) => updateSelectedChannels(event.target.id, event.target.checked),
              })}
            />
          </TableCellStyled>
        </TableRowStyled>
      ))}
    </TableBody>
  );

  const displaySelectedChannel = (chNo) => {
    const channel = channels.find((c) => c.chNo === chNo);
    return (
      <div key={chNo} className={styles.line}>
        <div className={styles.lineTitle}>{channel.chName}</div>
        <div className={styles.lineIcon} onClick={() => updateSelectedChannels(chNo, false)}>
          <RemoveIcon />
        </div>
      </div>
    );
  };

  const titleInputRef = useRef(null);
  
  const handlePencilIconClick = () => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  };
  return (
    <Grid container>
      <Confirm
        when={showPrompt}
        title={t(promptContent.title)}
        message={t(promptContent.message)}
        cancelText={t(promptContent.cancelText)}
        okText={t(promptContent.okText)}
        onOK={promptContent.okHandler}
        onCancel={promptContent.cancelHandler}
        isDelete={promptContent.isDelete}
      />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Grid item xs={12} className={classes.titleContainer}>
          <div className={`${classes.chartTitle} ${styles.chartTitle}`} onClick={handlePencilIconClick}>
            <input
              value={customChartTitle}
              {...register("title", {
                onChange: (event) => {
                  setCustomChartTitle(event.target.value);
                  setValue("title", event.target.value);
                },
                required: true,
              })}
              size={60}
              maxLength={31}
              ref={titleInputRef}
            />
            <div className={styles.titleIcon}>
              <img src={PencilIcon} width={19} height={20} style={{ cursor: 'pointer' }} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className={styles.chartSubtitle}>
          <div>{language()}</div>
          <div>
            <input
              value={subtitle}
              {...register("subtitle", {
                onChange: (event) => setSubtitle(event.target.value),
              })}
              placeholder={t(localizationKeys.CustomSubtitle)}
              size="38"
            />
          </div>
        </Grid>
        <Grid container className={styles.body} spacing={2}>
          <Grid item xs={6}>
            <div className={styles.searchControl}>
              <div>{t(localizationKeys.CustomSearchChannels)}</div>
              <div>
                <input
                  onChange={(evt) => updateSearchValue(evt.target.value)}
                  value={searchValue}
                  placeholder={t(localizationKeys.CustomSearchChannelsPlaceholder)}
                  size="50"
                  className={styles.searchInput}
                />
              </div>
              <div className={styles.showAll}>
                <span onClick={showAll}>{t(localizationKeys.CustomShowAll)}</span>
              </div>
            </div>
            <div className={styles.tableContainer}>
              {filteredChannels?.length ? (
                <TableContainer className={styles.table}>
                  <Table stickyHeader>
                    <TableHeader />
                    <TableCustBody />
                  </Table>
                </TableContainer>
              ) : (
                <div>
                  <div className={styles.tableMessage}>
                    {t(localizationKeys.CustomChartCountNote)}
                  </div>
                  <div className={styles.noMatchFound}>
                    <span>{t(localizationKeys.CustomNoMatchFound)}</span>
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={styles.graphPreview}>
              <div>
                <span className={styles.graphHeader}>
                  {selectedChannels.length === MAX_CHANNEL_COUNT
                    ? t(localizationKeys.CustomGraphPreviewFull)
                    : t(localizationKeys.CustomGraphPreview)}
                </span>
                <span className={styles.graphSubHeader}>
                  {selectedChannels.length === MAX_CHANNEL_COUNT
                    ? t(localizationKeys.CustomVariableNoteFull)
                    : t(localizationKeys.CustomVariableNote)}
                </span>
              </div>
              <div className={styles.graph}>
                <Chart
                  chartType={"preview"}
                  graphData={mockValues}
                  customChartLines={chartLines}
                  vesselId={vesselId}
                  type={GRAPH_TYPE.CUSTOM}
                  isInitialLoad={false}
                  isGraphDataLoading={false}
                  isMockPreview={true}
                />
              </div>
              <p className={styles.customChartDisclaimer}>
                This is an example graph and data curves may not reflect reality.
              </p>
            </div>
            <div className={styles.linesContainer}>
              <div className={styles.lines}>{selectedChannels?.map(displaySelectedChannel)}</div>
            </div>
            <div className={styles.controller}>
              <div className={styles.controllerLeft}>
                <input
                  type="submit"
                  className={isSubmitDisabled ? styles.disabledSubmitButton : styles.submitButton}
                  disabled={isSubmitDisabled}
                  value={t(localizationKeys.CustomSaveGraph)}
                />
                <span className={styles.cancelButton} onClick={onCancel}>
                  {t(localizationKeys.CustomCancel)}
                </span>
              </div>
              {selectedChartData && (
                <div className={styles.controllerRight}>
                  <IconButton onClick={onDelete} size="large">
                    <DeleteIcon />
                    <span className={styles.deleteButton}>{t(localizationKeys.CustomDelete)}</span>
                  </IconButton>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

CustomGraph.propTypes = {
  t: PropTypes.func,
  graph: PropTypes.string,
  vesselId: PropTypes.string,
  layoutStyle: PropTypes.object,
};

export default withTranslation()(CustomGraph);

