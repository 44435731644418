import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Popover } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";
import dayjs from "util/dayjs-init.js";

import { DateRangePicker } from "@blueprintjs/datetime";
import { DurationCalendarIcon } from "./durationCalendarIcon.jsx";
import { TimeRangePicker } from "./timeRangePicker.jsx";
import "./durationPicker.css";
import { alarmShortcut, emissionShortcut, trendShortcut } from "./shortcutSetting.js";
import { COLORS } from "../../constants/colors.js";
import { styled } from '@mui/system';
import { TimezoneToggleButton } from "./timezoneToggleButton.jsx";

export const DurationPicker = (props) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isEmission = false,
    minDate = undefined,
    disabled = false,
    isNoData = false,
    withTimePicker = false,
    singleLine = false,
    maxWidth = false,
  } = props;
  const { t } = useTranslation();
  const utcOffset = dayjs().utcOffset();
  const defaultStartDate = dayjs.utc(startDate).subtract(utcOffset, "m").toDate();
  const defaultEndDate = dayjs.utc(endDate).subtract(utcOffset, "m").toDate();
  const [newStart, setNewStart] = useState(startDate);
  const [newEnd, setNewEnd] = useState(endDate);
  const [isNoSelectedDate, setIsNoSelectedDate] = useState(true);
  const dateFormat = "YYYY-MM-DD";
  const dateTimeFormat = "YYYY-MM-DD HH:mm";

  // This is gross, basically get the long timezone and take the first letter of each word
  // dayjs documentation says .format('z') should return 'JST' but it actually returns GMT+9, so we have to do this instead
  const timezone = dayjs().format('zzz').match(/\b(\w)/g)?.join("");
  const toggleOptions = [{name: "UTC"},{name: timezone}]
  const [selectedToggleOption, setSelectedToggleOption] = useState(toggleOptions[0]);

  useEffect(() => {
    if (startDate !== "") {
      setNewStart(startDate);
    }
    if (endDate !== "") {
      setNewEnd(endDate);
    }
    setIsNoSelectedDate(startDate === "" || endDate === "");
  }, [startDate, endDate]);

  const changeHandler = (range) => {
    if (range.filter((v) => v === null).length === 0) {
      if(withTimePicker){
        setNewStart(dayjs.utc(range[0]).add(utcOffset, "m").format());
        setNewEnd(dayjs.utc(range[1]).add(utcOffset, "m").format());
      }else{
        setNewStart(dayjs.utc(range[0]).startOf("d").format());
        setNewEnd(dayjs.utc(range[1]).endOf("d").format());
      }
    }
  };

  const submitDurationChange = () => {
    const useUTC = selectedToggleOption.name === "UTC";
    let nextStart = useUTC ? dayjs.utc(newStart,true) : dayjs(newStart).subtract(utcOffset, "m").utc();
    let nextEnd = useUTC ? dayjs.utc(newEnd,true) : dayjs(newEnd).subtract(utcOffset, "m").utc();

    nextStart = nextStart.second(0).millisecond(0); // duration picker granularity is only minutes
    nextEnd = nextEnd.second(0).millisecond(0); // duration picker granularity is only minutes
    
    if (withTimePicker) {
      if (nextStart.format() !== "") {
        setStartDate(nextStart.format());
      }
      if (nextEnd.format() !== "") {
        setEndDate(nextEnd.format());
      }
      if (nextEnd.format() !== "") {
        setEndDate(nextEnd.format());
      } 
    } else {
      if (nextStart.format() !== "") {
        setStartDate(nextStart.format());
      }
      if (nextEnd.format() !== "") {
        setEndDate(nextEnd.format());
      }
    }
    setSelectedToggleOption(toggleOptions[0]); //set back to UTC
  };

  const shortcutHandler = ({ dateRange }) => {
    setNewStart(dayjs(dateRange[0]).utc().format());
    setNewEnd(dayjs(dateRange[1]).utc().format());
    setSelectedToggleOption(toggleOptions[0]); //set back to utc
  };

  const shortcutSetting = (isEmission) => {
    if (isEmission) {
      return emissionShortcut(t);
    } else if (withTimePicker) {
      return trendShortcut(t);
    } else {
      return alarmShortcut(t);
    }
  };

  const setMinDate = (isEmission) => {
    if (isEmission) {
      return undefined;
    } else if (minDate) {
      return minDate;
    }
    return new Date(dayjs.utc().startOf("d").subtract(1, "M"));
  };

  const setDisplayDate = (startDate, endDate) => {
    if (startDate === undefined || endDate === undefined) {
      return <span className="label">{t(localizationKeys.SelectDate_lower)}</span>;
    } else {
      const disPlayFormat = (format) =>
      singleLine === true
        ?
        <>
          <span>
            {`${dayjs.utc(startDate).format(format)} -
             ${dayjs
            .utc(endDate)
            .startOf("minute")
            .format(format)}`
          }
          </span>
        </>
        :
        <>
          <span>
            {`${dayjs.utc(startDate).format(format)}`}
          </span>
          <br/>
          <span>
          {
            `${dayjs
            .utc(endDate)
            .startOf("minute")
            .format(format)}`
          }
          </span>
        </>
;
      return withTimePicker ? disPlayFormat(dateTimeFormat) : disPlayFormat(dateFormat);
    }
  };

  const handleToggle = ((e, value) => {
    setSelectedToggleOption(value);
  });

  const TimezoneHolder = styled('div')({
    position: 'absolute',
    bottom: '10px',
    right: '10px'
  });

  return (
    <Popover
      placement="bottom-start"
      disabled={disabled}
      usePortal={false}
      fill={true}
      onClosed={submitDurationChange}
      className={maxWidth ? "max-width" : ""}
      content={
        <>
          <DateRangePicker
            onChange={changeHandler}
            onShortcutChange={shortcutHandler}
            allowSingleDayRange={true}
            defaultValue={[defaultStartDate, defaultEndDate]}
            reverseMonthAndYearMenus={true}
            minDate={setMinDate(isEmission)}
            maxDate={new Date()}
            shortcuts={shortcutSetting(isEmission)}
          />
          {withTimePicker && (
            <TimeRangePicker
              newStart={newStart}
              setNewStart={setNewStart}
              newEnd={newEnd}
              setNewEnd={setNewEnd}
            />
          )}
          {!withTimePicker && (
           <div style={{height: "40px", background: COLORS.componentBackColor}}/>
          )}
          <TimezoneHolder>
            <TimezoneToggleButton options={toggleOptions} selectedOption={selectedToggleOption} handler={handleToggle}/>
          </TimezoneHolder>
        </>
      }
    >
      <Button
        className="duration-button"
        small={true}
        disabled={disabled || isNoSelectedDate}
        text={isNoSelectedDate ? "PLEASE SELECT VESSEL" : setDisplayDate(startDate, endDate)}
        fill={true}
        alignText="left"
        rightIcon={
          <DurationCalendarIcon
            color={disabled || isNoData || isNoSelectedDate ? "rgba(255, 255, 255, 0.3)" : COLORS.staticState}
          />
        }
      />
    </Popover>
  );
};

DurationPicker.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  setStartDate: PropTypes.func,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  setEndDate: PropTypes.func,
  isEmission: PropTypes.bool,
  isNoData: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  disabled: PropTypes.bool,
  withTimePicker: PropTypes.bool,
  singleLine: PropTypes.bool,
};
