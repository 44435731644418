import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from "react";
import * as PropTypes from "prop-types";
import { styled } from '@mui/material/styles';


// Classes to use with sx prop
const classes = {
    toggleGroup: {
        backgroundColor: "#252d39",
        borderRadius: "4px",
        border: "1px solid #9c9c9c",
      },
}
// Styled wrapper for span, cant use sx with span
const LabelContainer = styled('span')({
    textTransform: "none",
    fontFamily: "Inter",
    fontSize: "10px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
})

// Styled wrapper for span, cant use sx with span
const IconContainer = styled('span')({
    width: "15px",
    height: "15px",
    "& svg": {
      transform: "scale(0.8) translate(-6px, -6px)",
    },
})

// Styled wrapper for ToggleButton to allow for conditional theming
const CustomToggle = styled(ToggleButton, {shouldForwardProp: (prop) => prop!=='isSelected'})(({theme, isSelected}) => ({
    height: "20px",
    width: "40px",
    border: "none",
    color: "rgba(255, 255, 255, 0.7)",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    "&.Mui-selected": {
      backgroundColor: "rgba(25, 178, 171, 0.5)",
    },
    "&.MuiToggleButton-label": {
      color: "rgba(25, 178, 171, 0.7)",
    },
    ...(isSelected && {
        backgroundColor: "#46576d !important",
        color: "#ffffff"
    })
}))

export const TimezoneToggleButton = ({ options = [], selectedOption, handler}) => {
  return (
    <ToggleButtonGroup
      value={selectedOption}
      exclusive
      aria-label="selectData"
      onChange={handler}
      sx={classes.toggleGroup}
    >
      {options.map((d) => (
        <CustomToggle
          key={d.name}
          value={d}
          data-testid={d.dataTestId}
          isSelected={selectedOption.name === d.name}
        >
          {d.icon ? (
            <IconContainer>{d.icon}</IconContainer>
          ) : (
            <LabelContainer>{d.name}</LabelContainer>
          )}
        </CustomToggle>
      ))}
    </ToggleButtonGroup>
  );
};

TimezoneToggleButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selectedOption: PropTypes.object,
};

