import React, { useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { STATUS_ABNORMAL } from "../../constants/alarmType.js";

import withStyles from '@mui/styles/withStyles';

//UI icon imports
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

//Vessel Icon imports
import VesselIcon from "../vesselImages/vesselIcon.js";
import { getAlarmHighestPriority, getMarkerColor } from "../../helpers/alarmTypeStyle.js";

//Redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  fleetNavigationData,
  setSelectedVessel,
  selectedVessel,
} from "../../model/slice/fleetNavigationDataSlice.js";
import { fleetAlarmSelector } from "../../model/slice/alarmSlice.js";

//Vessel type const
import { SHIP_GROUP_TYPE, SHIP_TYPE } from "../../constants/vesselShipType.js";
import { useDetectVesselConnection } from "../../hooks/useDetectVesselConnection.js";
import { VESSEL_TYPE } from "../../model/slice/vesselSlice.js";

import SortUtil from "../../helpers/sortUtil.js";
import { fleetEngineData } from "../../model/slice/fleetEngineDataSlice.js";
import "../../../css/marker.css";

const styles = (theme) => ({
  fleetList: {
    borderRadius: "5px",
    backgroundColor: "rgba(2, 2, 2, 0.7)",
    fontSize: "12px",
    color: "#ffffff",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    paddingTop: "8px",
  },
  fleetListExpanded: {
    display: "flex",
    flexDirection: "column",
  },
  fleetListCollapsed: {
    height: "35px",
  },
  expandIcon: {
    display: "block",
    margin: "auto",
    cursor: "pointer",
    fill: "#19b2ab",
  },
  dataEntry: {
    cursor: "pointer",
    height: "37px",
    width: "100%",
    display: "table",
    tableLayout: "fixed",
    "&:hover": {
      backgroundColor: "rgba(2,2,2,0.3)",
    },
  },
  shipSelected: {
    backgroundColor: "rgba(2, 2, 2, 0.5) !important",
    cursor: "pointer",
    height: "37px",
    width: "100%",
    display: "table",
    tableLayout: "fixed",
  },
  fleetTable: {
    width: "100%",
    flex: 1,
    display: "block",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "overflow-y": "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  fleetNameHolder: {
    display: "inline-table",
  },
  connectedFleetName: {
    display: "table-cell",
    verticalAlign: "middle",
    color: "#19b2ab",
  },
  disconnectedFleetName: {
    display: "table-cell",
    verticalAlign: "middle",
    color: "#ffffff",
  },
  disConnectedVessel: {
    zIndex: "100",
  },
  vesselIcon: {
    // transform: "scale(0.6)",
    position: "relative",
    margin: "auto",
    width: "32px",
    height: "24px",
    // zIndex: 99,
    zIndex: 999,
  },
  alarmMarker: {
    position: "relative",
    width: "34px",
    height: "34px",
    "& .fl-marker, & .fl-marker:before, & .fl-marker:after": {
      top: "-6px",
      left: "2px",
      position: "absolute",
      borderRadius: "50%",
      zIndex: 600,
    },
  },
});

const FleetList = (props) => {
  const fleetNavigationDispatch = useDispatch();
  const fleetNavigationDataState = useSelector(fleetNavigationData);
  const fleetEngineDataState = useSelector(fleetEngineData);
  const currentVessel = useSelector(selectedVessel);
  const fleetAlarmData = useSelector(fleetAlarmSelector);
  const fleetNavigationDataStateRef = React.useRef();
  fleetNavigationDataStateRef.current = fleetNavigationDataState;
  const sortUtil = new SortUtil();
  const [isExpanded, setIsExpanded] = React.useState(true); //set default exapnd to true
  const { connections } = useDetectVesselConnection();

  //sort the list
  const sortedList = sortUtil.vesselListSort(fleetNavigationDataState, fleetEngineDataState);

  const getDataList = useMemo(() => {
    if (!isExpanded) {
      return;
    }

    return sortedList.map(function (object, i) {
      const shipType =
        object.vesselLatestAisData.length > 0 ? object.vesselLatestAisData[0].shipType : 0;
      const shipTypeText = SHIP_GROUP_TYPE[SHIP_TYPE[shipType]];
      const shipClassText = object.vessel_firstClass !== "" ? `[${object.vessel_firstClass}]` : "";
      const isSEPvessel = object?.vesselType === VESSEL_TYPE.SEP;
      const alarmHighestPriority = getAlarmHighestPriority(
        fleetAlarmData.filter((f) => f.vesselId === object.vessel_id)
      );
      const isVesselDisconnected = connections.includes(object.vessel_id);
      const isSelected = object.vessel_id === currentVessel?.vessel_id;

      const handleItemClick = (event) => {
        event.stopPropagation(); // Prevent the click from propagating
        setSelected(object.vessel_id);
      };

      return (
        <tr
          key={object.vessel_id}
          className={isSelected ? props.classes.shipSelected : props.classes.dataEntry}
          onClick={handleItemClick}
        >
          <td className={props.classes.fleetNameHolder}>
            <>
              <span className={clsx(props.classes.fleetName, props.classes.alarmMarker)}>
                {!isVesselDisconnected && alarmHighestPriority?.alarmStatus === STATUS_ABNORMAL && (
                  <span
                    className="marker fl-marker"
                    style={{
                      filter:
                        alarmHighestPriority != null ? getMarkerColor(alarmHighestPriority) : "",
                    }}
                  />
                )}
                <img
                  className={clsx(
                    props.classes.vesselIcon,
                    isVesselDisconnected ? props.classes.disConnectedVessel : null
                  )}
                  src={VesselIcon(isSEPvessel ? 52 : shipType, 0, 1, isVesselDisconnected, false)}
                  alt="vessel icon"
                />
              </span>
              {!isVesselDisconnected && (
                <span className={props.classes.connectedFleetName}>
                  {object.vessel_name}{" "}
                  <span style={{ color: "#ffffff" }}>
                    {shipTypeText !== "" ? "- " + shipTypeText : ""} {shipClassText}
                  </span>
                </span>
              )}
              {isVesselDisconnected && (
                <span className={props.classes.disconnectedFleetName}>
                  {object.vessel_name}{" "}
                  <span style={{ color: "#9c9c9c" }}>
                    {shipTypeText !== "" ? "- " + shipTypeText : ""} {shipClassText}
                  </span>
                </span>
              )}
            </>
          </td>
        </tr>
      );
    });
  }, [sortedList, fleetAlarmData, connections]);

  const getExpandIcon = () => {
    if (isExpanded) {
      return (
        <ExpandMoreIcon
          className={props.classes.expandIcon}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />
      );
    } else {
      return (
        <ExpandLessIcon
          className={props.classes.expandIcon}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />
      );
    }
  };

  const setSelected = (vessel_id) => {
    fleetNavigationDispatch(setSelectedVessel(vessel_id));
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={props.classes.fleetList}
      onClick={toggleExpand}
      style={{ cursor: "pointer" }} // make the cursor to a pointer
    >
      <div
        className={isExpanded ? props.classes.fleetListExpanded : props.classes.fleetListCollapsed}
        style={{
          maxHeight: props.height,
        }}
      >
        <div>{getExpandIcon()}</div>
        <table className={props.classes.fleetTable}>
          <tbody>{getDataList}</tbody>
        </table>
      </div>
    </div>
  );
};

FleetList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  height: PropTypes.number,
};

export default withStyles(styles)(FleetList);
