import { fetchVesselHistoricalGpggaData } from "../../api/historicalData";
import { fetchVesselVoyageStatus } from "../../api/voyageStatus";
import {
  STATUS_KEY,
  LAST_DEPARTURE_TIME_KEY,
  CURRENT_PARK_TIME_KEY,
  MOVING,
  STOPPED
} from "../../constants/routeDisplay"
import dayjs from "util/dayjs-init.js";

// get distance between two points (lat, lon), in km
function haversine(p1, p2) {
  const R = 6371;
  const dLat = (p1.lat - p2.lat) * Math.PI / 180;
  const dLon = (p1.lng - p2.lng) * Math.PI / 180;
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) + 
    Math.cos(p1.lat*Math.PI/180) * Math.cos(p2.lat*Math.PI/180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  return R * c; 
}

export const setRoute = async (map, vesselId, curPosition, routePolyline) => {
  if (!map) {
    routePolyline[1].setMap(null);
    return;
  }
  let startDate = dayjs.utc().subtract(10, "days").format();
  let endDate = dayjs.utc().format();

  const statusRes = await fetchVesselVoyageStatus(vesselId);
  const status = statusRes["voyageStatus"];

  if (status[STATUS_KEY] == STOPPED) {
    if (!status[LAST_DEPARTURE_TIME_KEY] && vesselId == routePolyline[0]) {
      let bounds = new google.maps.LatLngBounds();
      bounds.extend(curPosition);
      google.maps.event.addListenerOnce(map, "bounds_changed", function () {
        this.setZoom(Math.min(this.getZoom(), 7));
      });
      map.fitBounds(bounds);
      return;
    }
    else {
      startDate = status[LAST_DEPARTURE_TIME_KEY];
      endDate = status[CURRENT_PARK_TIME_KEY];
    }
  }
  else if (status[STATUS_KEY] == MOVING) {
    startDate = status[LAST_DEPARTURE_TIME_KEY];
  }

  fetchVesselHistoricalGpggaData(vesselId, startDate, endDate).then((gpggaData) => {
    let coords = [];
    let coordsTemp = [];
    if (gpggaData.historicalGpggaData) {
      let bounds = new google.maps.LatLngBounds();
      gpggaData?.historicalGpggaData.forEach((data) => {
        data?.features.forEach((obj) => {
          const currLat = obj?.geometry?.coordinates[1];
          const currLng = obj?.geometry?.coordinates[0];
          const currTime = obj?.properties?.dateTime;
          if (
            currLat <= 90 &&
            currLat >= -90 &&
            currLng <= 180 &&
            currLng >= -180
          ) {
            coordsTemp.push({
              lat: currLat,
              lng: currLng,
              time: currTime
            });
          }
        });
      });
      const SPD_THRESHOLD = 64.82; // km/h, 35 kt
      let spdTable = [];
      for (let i = 0; i < coordsTemp.length - 1; i++) {
        const duration = dayjs(coordsTemp[i+1].time).diff(dayjs(coordsTemp[i].time), 'hour', true);
        spdTable.push(haversine(coordsTemp[i], coordsTemp[i+1]) / duration);
      }
      let teleportIdx = spdTable.map((v, idx) => v > SPD_THRESHOLD ? idx : '').filter(Number.isFinite);
      let start = 0;
      if (teleportIdx.length == 0) coords = coordsTemp;
      else {
        if (teleportIdx[0] == 0) {
          start = 1;
          teleportIdx.splice(0, 1);
        }
        for (let i = 0; i < teleportIdx.length - 1; i += 2) {
          coords = coords.concat(coordsTemp.slice(start, teleportIdx[i]+1));
          start = teleportIdx[i+1] + 1;
        }
        coords = coords.concat(coordsTemp.slice(start));
      }
      coords.forEach((v) => {
        bounds.extend({
          lat: v.lat,
          lng: v.lng
        })
      })
      if (vesselId == routePolyline[0] && coords.length > 0) {
        coords.push(curPosition);
        bounds.extend(curPosition);
        routePolyline[1].setPath(coords);
        routePolyline[1].setMap(map);
        google.maps.event.addListenerOnce(map, "bounds_changed", function () {
          this.setZoom(Math.min(this.getZoom(), 7));
        });
        map.fitBounds(bounds);
      }
    }
  });
  return;
};

export const setRouteH = (map, vesselId, routePolylineH) => {
  if (!map) {
    routePolylineH[1].setMap(null);
    return;
  }
  fetchVesselHistoricalGpggaData(vesselId, 120, 360).then((gpggaData) => {
    let coords = [];
    if (gpggaData.historicalGpggaData) {
      gpggaData?.historicalGpggaData.forEach((v) => {
        if (
          v["latitude"] <= 90 &&
          v["latitude"] >= -90 &&
          v["longitude"] <= 180 &&
          v["longitude"] >= -180
        ) {
          coords.push({
            lat: v["latitude"],
            lng: v["longitude"],
          });
        }
      });
      // console.log(coords);
      if (vesselId == routePolylineH[0] && coords.length > 0) {
        routePolylineH[1].setPath(coords);
        routePolylineH[1].setMap(map);
      }
    }
  });
  return;
};
