import React, { useCallback, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { fetchThumbnail } from "../../api/thumbnail";
import { CircularProgress } from "@mui/material";
import DefaultVessel from "../../../assets/images/vessels/ship-images/default_vessel_image.png";
import VesselImage from "../vesselImages/vesselImage";

const useStyles = makeStyles((theme) => ({
  loadingProgressContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  picture: {
    width: "100%",
    height: "100%",
  },
  defaultPicture: {
    height: "25%",
    width: "25%",
    marginLeft: "37%",
    marginTop: "18%",
  },
}));

const Thumbnail = React.memo((props) => {
  const { vesselId, vesselType } = props;
  const classes = useStyles();
  const [thumbnail, setThumbnail] = useState();
  const [hasThumbnail, setHasThumbnail] = useState(false);

  const defaultThumbnail = () => {
    if (vesselType === null) setThumbnail(DefaultVessel);
    else setThumbnail(VesselImage());
  };

  const loadThumbnail = async () => {
    if (vesselId === null || vesselId === undefined) return;
    const { thumbnail } = await fetchThumbnail(vesselId);
    if (
      thumbnail instanceof Blob &&
      thumbnail.type !== "application/json" &&
      thumbnail.type !== "application/json;charset=utf-8"
    ) {
      const newFileReader = new FileReader();
      newFileReader.readAsDataURL(thumbnail, thumbnail);
      newFileReader.onload = (e) => {
        setThumbnail(e?.target?.result);
      };
      setHasThumbnail(true);
    } else {
      defaultThumbnail();
    }
  };

  useEffect(() => {
    setHasThumbnail(false);
    if (thumbnail !== null) setThumbnail(null);
    const abortCtrl = new AbortController();
    loadThumbnail();

    return () => {
      abortCtrl.abort();
    };
  }, [vesselId]);

  useEffect(() => {
    if (thumbnail === null || thumbnail === DefaultVessel) {
      defaultThumbnail();
    }
  }, [vesselType]);

  const Image = useCallback(() => {
    return thumbnail === null ? (
      <div className={classes.loadingProgressContainer}>
        <CircularProgress />
      </div>
    ) : (
      <img src={thumbnail} alt={"vessel-image"} className={hasThumbnail ? classes.picture : classes.defaultPicture} />
    );
  }, [thumbnail]);

  return (
    <React.Fragment>
      <Image />
    </React.Fragment>
  );
});

Thumbnail.propTypes = {
  vesselId: PropTypes.string,
  vesselType: PropTypes.string,
};

export default Thumbnail;
