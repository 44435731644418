import React from "react";
import { Navigate } from "react-router-dom";

//MS Graph imports
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../constants/authConfig";

//API Imports
import { loginOid, loginValidateToken } from "../../api/auth.js";

//Cookie imports
import Cookies from "universal-cookie";
import {
  COOKIE_AUTH_KEY,
  SERVICE_POLICY,
  SERVICE_POLICY_SETTING,
} from "../../constants/constants.js";

// Logger
import { logger } from "../../api/logger";

import { detectDevice, DEVICE_TYPE } from "../../constants/detectDevice.js";

const Authorize = (props) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [redirect, setRedirect] = React.useState(<div></div>);

  const requestProfileData = async () => {
    //Silent token check
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    const userToken = await loginOid(response.account.localAccountId);
    const cookies = new Cookies();

    if (userToken.isLoginSuccess === true) {
      const isMobile = detectDevice !== DEVICE_TYPE.PC;
      const options = { path: "/" };
      if (isMobile) {
        options["expires"] = new Date(Date.now() + 86400000);
      }
      cookies.set(COOKIE_AUTH_KEY, userToken.token, options);
      const policyCheck = cookies.get(SERVICE_POLICY, { path: "/" });

      const userData = await loginValidateToken();

      // check userPolicy cookie then If there are no values or "connect-false", redirect to service policy page
      if (
        userData.isValid &&
        (policyCheck === undefined || policyCheck === SERVICE_POLICY_SETTING.CONNECT_FALSE)
      ) {
        // In initial login, FE dosn't set userContext until user agrees our service policy.
        setRedirect(
          <Navigate
            to="/policyconfirm"
            state={{userData: userData.userData}}
          />
        );
      }
      if (userData.isValid) {
        props.setUserContext(userData.userData);
        setRedirect(<Navigate to="/main" />);
      } else {
        logger.error("Invalid authentication.");
        alert("Invalid authentication");
        props.setUserContext(null); //Remove currently stored user context.
        setRedirect(<Navigate to="/" />);
      }
    } else {
      logger.error("No info on database.");
      alert("No info on database");
      props.setUserContext(null); //Remove currently stored user context
      setRedirect(<Navigate to="/" />);
    }
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      logger.info("User is authenticated.");
      requestProfileData();
    }
  }, [isAuthenticated]);

  return redirect;
};

export default Authorize;
