import { useState, useEffect } from "react";
import { DataFrame, merge, toJSON } from "danfojs";
import { fetchChannelDataByChartId, fetchChannelModAllData } from "../../api/channel";
import { CSV_EXPORT_TAGS, HANDLING_PRESS_LOAD_TAGS, HANDLING_PRESS_UNLOAD_TAGS, LINE_CHART_TAGS, TEMP_CH_NO } from "../../constants/cargo/charts";
import { CH_NO_LIST } from "../../constants/cargo/chNoList";
import { PRESS_TABS } from "../../constants/cargo/constants";
import { fetchVesselNavDataForCargo } from "../../api/navigationData";
import dayjs from "util/dayjs-init";

export const generateDanfoJsData = (data, fetchedData, dataNo, minuteInterval) => {
  if(fetchedData.length === 0 || data.length === 0)
    return data;

  const stripDateTime = (dateString, interval) => {
    return interval < 1 ? dayjs.utc(dateString).millisecond(0).format() : dayjs.utc(dateString).second(0).millisecond(0).format();
  }

  const columns = [
    ...(Object.values(dataNo.map(d => d.chNo))),
    "dateTime",
    "localDateTime",
    "流速",
    "圧力損失(P8-P1)",
    "圧力損失(P8-P2)",
    "圧力損失(P1-P5)",
    "圧力損失(P1-P6)",
    "圧力損失(P2-P5)",
    "圧力損失(P2-P6)",
    "strippedDateTime",
  ];
  
  let uniqueData = [];
  const isUnique = (v) => {
    if (!uniqueData.includes(v.strippedDateTime)) {
      uniqueData.push(v.strippedDateTime);
      return true;
    }
    return false;
  };
  const filteredNavData = fetchedData.map((v) => ({...v, strippedDateTime: stripDateTime(v.dateTime, minuteInterval)})).filter(isUnique);

  const strippedData = data.map((v) => ({...v, strippedDateTime: stripDateTime(v.dateTime, minuteInterval)}));
  const channelData = new DataFrame(strippedData, { columns });
  const filteredNavDataFrame = new DataFrame(filteredNavData);

  let mergedData = merge({ left: channelData, right: filteredNavDataFrame, on: ["strippedDateTime"], how: "left"});

  mergedData.drop({columns: ['strippedDateTime', 'dateTime_1'], inplace: true});
  return toJSON(mergedData.fillNa(''));
}

export const fetchTemperatureTrendData = (params) => {
  const { vesselId, duration, goLatest, setGoLatest, isLatest, latestData } = params;
  const [cargoTempData, setCargoTempData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!vesselId) return;
    setIsLoading(true);
    const fetchData = async () => {
      if(!duration || goLatest || isLatest){
        if(latestData?.length) {
          const tempChartData = TEMP_CH_NO.map((v, i) => {
            const value = Number(latestData.find((data) => String(data.chNo) === String(v.chNo))?.inputData);
            return { ...TEMP_CH_NO[i], value };
          });
          setCargoTempData(tempChartData);
        }else{
          setCargoTempData([]);
        };
        setGoLatest(false)
     }else{
      const trendData = (
        await fetchChannelDataByChartId(
          vesselId,
          null,
          duration?.startDate,
          duration?.endDate,
          1,
          1,
          "",
          "",
          "cargotemp",
        ).catch((e) => [])
      )?.customData;
      if (trendData?.length) {
        const tempChartData = TEMP_CH_NO.map((v, i) => {
          const value = Number(trendData[0].data.find((data) => String(data.chNo) === String(v.chNo))?.data);
          return { ...TEMP_CH_NO[i], value };
        });
        setCargoTempData(tempChartData);
      } else {
        setCargoTempData([]);
      }
    }
    setIsLoading(false);
    };
    fetchData();
  }, [vesselId, JSON.stringify(duration)]);
  return { cargoTempData, isLoading };
};

export const fetchPressureTrendData = (params) => {
  const { vesselId, duration, interval, limit = "" } = params;
  const category = "CargoSystem";
  const [cargoPressData, setCargoPressData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState({});
  const trendChartNo = LINE_CHART_TAGS.map((v) => CH_NO_LIST.find((u) => v.tag === u.tag));

  useEffect(() => {
    if (fetchedData?.length) {
      const loadData = [];
      const unloadData = [];
      const taggedData = fetchedData.map((e) => {
        const data = trendChartNo.reduce((accumulator, currentValue, index) => {
          const value = Number(
            e.data.find((data) => String(data.chNo) === String(currentValue.chNo))?.data
          );
          if (value) {
            accumulator.push({ [trendChartNo[index].tag]: value });
          }
          return accumulator;
        }, []);
        const objData = {};
        data.forEach((e) => {
          Object.assign(objData, e);
        });
        return { dateTime: Date.parse(e.dateTime), ...objData };
      });
      taggedData.forEach(data => {
        loadData.push(Object.fromEntries(Object.entries(data).filter(([key]) => HANDLING_PRESS_LOAD_TAGS.some(tag => tag.tag === key || key === 'dateTime'))));
        unloadData.push(Object.fromEntries(Object.entries(data).filter(([key]) => HANDLING_PRESS_UNLOAD_TAGS.some(tag => tag.tag === key || key === 'dateTime'))));
      });
      setCargoPressData({
        [PRESS_TABS.LOADING]: loadData,
        [PRESS_TABS.UNLOADING]: unloadData,
      });
      setIsLoading(false);
    } 
    else {
      setCargoPressData({
        [PRESS_TABS.LOADING]: [],
        [PRESS_TABS.UNLOADING]: [],
      });
      setIsLoading(false);
    }
  }, [fetchedData])

  useEffect(() => {
    if (!vesselId || !duration) return;
    const fetchData = async () => {
      setIsLoading(true);
      const trendData = await fetchChannelDataByChartId(
        vesselId,
        null,
        duration?.startDate,
        duration?.endDate,
        interval,
        limit,
        category,
        '',
        "cargopressure",
      );
      if (trendData?.customData?.length) {
        setFetchedData(trendData.customData);
      } else {
        setCargoPressData({
          [PRESS_TABS.LOADING]: [],
          [PRESS_TABS.UNLOADING]: [],
        });
        setIsLoading(false);
      }
    };
    fetchData();
  }, [vesselId, JSON.stringify(duration)]);

  return { cargoPressData, isLoading };
};

export const fetchTrendDataForCSV = async (params) => {
  const { vesselId, interval, limit = "", startDate, endDate, tags } = params;
  const CSVDataNo = CSV_EXPORT_TAGS.map((v) => CH_NO_LIST.find((u) => v.tag === u.tag)); // a list of channels to export, found by looking for tags defined in CSV_EXPORT_TAGS

  const getChannelName = async (vesselId) => {
    return fetchChannelModAllData(vesselId);
  };

  const insertNavigationData = async (data) => {
    return fetchVesselNavDataForCargo(vesselId, startDate, endDate, interval)
      .then((fetchedNav) => {
        const renamedData = fetchedNav.data.map((v) => ({
          dateTime: v['gpgga.dateTime'],
          "真風向": v['wimwvt.direction'],
          "真風速": v['wimwvt.force'],
          "相対風向": v['wimwvr.direction'],
          "相対風速": v['wimwvr.force'],
          "対地船速": v['gpvtg.speedKnot'],
          "緯度": v['gpgga.latitude'].toFixed(2),
          "経度": v['gpgga.longitude'].toFixed(2)
        }));
        return generateDanfoJsData(data, renamedData, CSVDataNo, interval);
      }).catch((e) => {
        console.error(e);
        return data;
      });
  };

  //for LCO2
  const insertCalculationValue = (data) => {
    return data.map((v) => {
      const P1 = Number(v[CH_NO_LIST.find((v) => v.tag === "P1").chNo]);
      const P2 = Number(v[CH_NO_LIST.find((v) => v.tag === "P2").chNo]);
      const P5 = Number(v[CH_NO_LIST.find((v) => v.tag === "P5").chNo]);
      const P6 = Number(v[CH_NO_LIST.find((v) => v.tag === "P6").chNo]);
      const P8 = Number(v[CH_NO_LIST.find((v) => v.tag === "P8").chNo]);
      const pipeArea = Math.PI * (199 / 2) ** 2;
      const flowSpeed = (
        (10000 * Number(v[CH_NO_LIST.find((v) => v.tag === "O9").chNo])) /
        (36 * pipeArea)
      ).toFixed(1);

      const calculateObject = {
        流速: flowSpeed,
        "圧力損失(P8-P1)": (P8 - P1).toFixed(2),
        "圧力損失(P8-P2)": (P8 - P2).toFixed(2),
        "圧力損失(P1-P5)": (P1 - P5).toFixed(2),
        "圧力損失(P1-P6)": (P1 - P6).toFixed(2),
        "圧力損失(P2-P5)": (P2 - P5).toFixed(2),
        "圧力損失(P2-P6)": (P2 - P6).toFixed(2),
      };
      return Object.assign(v, calculateObject);
    });
  };

  const removeUnnecessaryData = (data) => {
    const chNos = tags.map((v) => CH_NO_LIST.find((u) => v.tag === u.tag).chNo)
    const newData = data?.map((v) => {
      const keys = Object.keys(v);
      chNos.forEach(chNo => {
        if (!keys.includes(chNo)) keys.push(chNo);
      })
      const returnValue = keys.reduce((accumulator, currentValue, index) => {
        Object.assign(accumulator, {[currentValue]: v[currentValue]});
        return accumulator;
      }, {})
      return returnValue;
    })
    return newData;
  }

  const sortAndReplaceWithChannelName = async (data) => {
    const channelNameList = (await getChannelName(vesselId).then((ret) => ret?.channelData).catch((e) => []));
    if(!channelNameList?.length) {
      return data.map((v) => {
        const pairs = Object.entries(v);
        pairs.map((e) => {
          if(!isNaN(e[0])) {
            e[0] = 'CH' + e[0];
          }
          return e;
        })
        return Object.fromEntries(pairs);
      })
    } else {
      return data.map((v) => {
        //sort by channelNo
        const pairs = Object.entries(v);
        pairs.sort((p1, p2) => {
          const p1Key = p1[0];
          const p2Key = p2[0];
          if (p1Key < p2Key) return -1;
          if (p1Key > p2Key) return 1;
          return 0;
        });
        pairs.map((e) => {
          //replace channelNo with channelName
          const channelName = channelNameList?.find((v) => v.chNo === e[0])?.chName;
          e[0] = channelName ?? e[0];
          return e;
        });
        return Object.fromEntries(pairs);
      });
    }
  };

  if (!vesselId) return;
  const fetchData = async () => {
    let CSVData = [];
    const trendData = await fetchChannelDataByChartId( //this fetches all channels
      vesselId,
      null,
      startDate,
      endDate,
      interval,
      limit,
    );
    if (trendData?.customData?.length) {
      const taggedData = trendData.customData.map((e, index) => {
        const data = CSVDataNo.reduce((accumulator, currentValue, index) => {
          const value = Number(
            e.data.find((data) => String(data.chNo) === String(currentValue.chNo))?.data
          );
          if (value != null && !isNaN(value)) {
            accumulator.push({ [CSVDataNo[index].chNo]: value });
          }
          return accumulator;
        }, []);
        const objData = {};
        data.forEach((e) => {
          Object.assign(objData, e);
        });
        return { dateTime: e.dateTime, ...objData };
      });
      //compress same dateTime data.
      const newData = taggedData.reduce((accumulator, current) => {
        let returnData = { ...current };
        const target = CSVData.find((v) => v.dateTime === current.dateTime);
        if (target) {
          returnData = Object.assign(returnData, target);
        }
        accumulator.push(returnData);
        return accumulator;
      }, []);

      newData.forEach((v) => { v.localDateTime = dayjs.utc(v.dateTime).local().format() }); //add a column for local date time

      CSVData = newData;
    } else {
      return [];
    }
    CSVData = insertCalculationValue(CSVData);
    CSVData = removeUnnecessaryData(CSVData);
    CSVData = await insertNavigationData(CSVData);
    CSVData = await sortAndReplaceWithChannelName(CSVData);
    return CSVData;
  };
  return fetchData()
    .then((value) => {
      const CSVTrendData = value;
      const isLoading = false;
      return { CSVTrendData, isLoading };
    })
    .catch((e) => {
      return { CSVTrendData: [], isLoading: false };
    });
};
