import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys";
import { useEngineLatestData } from "./engine/useEngineLatestData";
import { useVesselSettings } from "../../helpers/vesselSetting/useVesselSettings";
import { currentVesselValue } from "../../model/slice/vesselSlice";
import { MIN_ENGINE_PAPER_HEIGHT, TITLE_PAPER_HEIGHT } from "../../constants/monitoring/constants";
import LoadDiagram from "./engine/loadDiagram/loadDiagram.jsx";
import ChartPowerCurve from "./engine/powerCurve/powerCurve.jsx";
import EngineSelectPanel from "./engine/engineSelectPanel.jsx";
import CppAnglePanel from "./engine/cppAnglePanel.jsx";
import Electric from "./electric/electric.jsx";
import { fetchVesselAsync } from "../../model/async/vesselAsync";
import { TabSelector } from "../tabSelector/tabSelector.jsx";
import { NoData } from "../offlineComponent/noData.jsx";
import { useEngineHistoricalData } from "./engine/useEngineHistoricalData";
import { useParams } from "react-router-dom";
import { checkIsNoData, checkIsShownLoadDiagram, engineSelect } from "../../util/monitoring/monitoring.js";
import { calcBHP } from "../../util/monitoring/brakeHorsePowerCalc.jsx";

const paper = (theme) => ({
  backgroundColor: "rgba(44, 58, 72, 0.3)",
  padding: theme.spacing(2),
  minHeight: `${MIN_ENGINE_PAPER_HEIGHT}px`,
  borderRadius: "12px",
});

const paperTitle = (color) => ({
  "& p": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color,
    margin: "0",
  },
  width: "max",
  height: `${TITLE_PAPER_HEIGHT}px`,
  backgroundColor: "rgba(44, 58, 72, 0.4)",
  margin: "-16px -16px 0px -16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px 12px 0px 0px",
  boxShadow: "none",
});

const useStyles = makeStyles((theme) => ({
  paper: {
    ...paper(theme),
    textAlign: "center",
    verticalAlign: "top",
  },
  controllerPaper: {
    ...paper(theme),
    [theme.breakpoints.up("lg")]: {
      height: '100%',
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "unset",
      height: "unset",
    },

  },
  controllerCPPPaper: {
    ...paper(theme),
    marginTop: "10px",
    minHeight: `120px`,
    [theme.breakpoints.up("lg")]: {
      height: "120px",
    },
    [theme.breakpoints.down('lg')]: {
      height: "140px",
      minHeight: "140px",
      position: "flex",
    },
  },
  paperTitle: {
    ...paperTitle("#19b3ab"),
  },
  disconnectedPaperTitle: {
    ...paperTitle("#c8c8c8"),
  },
  headSpacer: {
    [theme.breakpoints.up("sm")]: {
      height: "8px",
    },
  },
  middleSpacer: {
    [theme.breakpoints.up("sm")]: {
      height: "24px",
    },
  },
  solidCheck: {
    border: "solid white",
  },
  diagram: {
    minHeight: `${MIN_ENGINE_PAPER_HEIGHT - theme.spacing(2) * 2 - TITLE_PAPER_HEIGHT}px`,
  },
  noContent: {
    position: "relative",
    top: "25vh",
  },
}));

const sxStyles = {
  containerBase: {
    marginRight: {
      xs: "32px",
      lg: "100px",
    },
    marginLeft: {
      xs: "32px",
      lg: "100px",
    }
  }
}

const commonTabs = (t) => [
  {
    name: t(localizationKeys.Monitoring_lower),
    id: "monitoring",
  },
];

const MonitoringPage = ({layoutStyle = null, i18n }) => {
  const {vesselId} = useParams();
  const { t } = useTranslation();
  const { vesselSettings } = useVesselSettings({ vesselId });
  const dispatch = useDispatch();
  const NoME = useSelector(currentVesselValue("NoME", ""));
  const styles = useStyles();
  const classes = { ...styles, layoutStyle };
  const { latestNavigationData, latestEngineData, isLoading, isNoEngine } = useEngineLatestData({
    vesselId,
  });
  const { historicalNavigationData, historicalEngineData } =
    useEngineHistoricalData({
      vesselId,
    });

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [noPowers, setnoPowers] = useState(false);
  const [engines, setEngines] = useState([]);
  const [enginePositions, setEnginePositions] = useState([]);
  const [fixEnginePositions, setFixEnginePositions] = useState([]);
  const [isNoData, setIsNoData] = useState(true);

  const vesselBHP = calcBHP(vesselId, latestEngineData);

  useEffect(() => {
    if (!isLoading) setIsInitialLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setIsInitialLoading(true);
    setEnginePositions([]);
    if (vesselId) {
      dispatch(fetchVesselAsync({ vesselId }));
    }
  }, [vesselId]);

  useEffect(() => {
    if (latestEngineData && NoME) {
      setnoPowers(checkIsShownLoadDiagram(NoME, latestEngineData, vesselBHP) || isNoEngine);
      setEngines(engineSelect(t, NoME));
      if (latestNavigationData) {
        const targetLatestNavigationData = latestNavigationData.filter(
          (value) => value.vessel_id === vesselId
        );
        if (targetLatestNavigationData) {
          setIsNoData(checkIsNoData(NoME, latestEngineData, targetLatestNavigationData[0]));
        }
      }
    } else setEngines([]);
  }, [latestEngineData, latestNavigationData, NoME, i18n?.language]);

  useEffect(() => {
    if (isInitialLoading) return;
    if (engines.length > 0) {
      if (enginePositions.length === 0) setEnginePositions([engines[0].number]);
      setIsInitialLoading(false);
    } else setEnginePositions([]);
  }, [engines]);

  useEffect(() => {
    if (enginePositions.length > 0)
      setFixEnginePositions(
        engines.filter((d) => enginePositions.includes(d.number)).map((d) => d?.position)
      );
    else setFixEnginePositions([]);
  }, [enginePositions]);

  const paperTitle = isNoData ? classes.disconnectedPaperTitle : classes.paperTitle;

  return (
    <React.Fragment>
      {/* Monitoring page doesn't have another tab */}
      <TabSelector tabs={commonTabs(t)} selectedOne={"monitoring"} clickHandler={() => {}} />
      <Grid container spacing={2} paddingTop={2} sx={sxStyles.containerBase}>
        {/* Hidden when vessel type is SEP */}
        {NoME > 0 && !noPowers && !isNoData && (
          <React.Fragment>
            <Grid lg={5} md={6} sm={12} xs={12}>
              <Paper className={classes.paper} sx={{height: "100%"}}>
                <Paper className={paperTitle}>
                  <p>{t(localizationKeys.LoadDiagram_upper)}</p>
                </Paper>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.diagram}
                >
                  <Grid xs={12}>
                    <LoadDiagram
                      vesselId={vesselId}
                      engineLatestData={latestEngineData}
                      selectEngines={fixEnginePositions}
                      isLoading={isLoading}
                      isNoData={isNoData}
                      isNoEngine={noPowers}
                      isInitialLoading={isInitialLoading}
                      vesselSettings={vesselSettings}
                      vesselBHP={vesselBHP}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid lg={5} md={6} sm={12} xs={12}>
              <Paper className={classes.paper} id="powerCurve" sx={{height: "100%"}}>
                <Paper className={paperTitle}>
                  <p>{t(localizationKeys.PowerCurve_upper)}</p>
                </Paper>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.diagram}
                >
                  <Grid xs={12}>
                    <ChartPowerCurve
                      vesselId={vesselId}
                      enginePositions={fixEnginePositions}
                      engineLatestData={latestEngineData}
                      navigationLatestData={latestNavigationData}
                      isLoading={isLoading}
                      isNoData={isNoData}
                      isNoEngine={noPowers}
                      isInitialLoading={isInitialLoading}
                      engineHistoricalData={historicalEngineData}
                      navigationHistoricalData={historicalNavigationData}
                      vesselBHP={vesselBHP}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid lg={2} md={12} sm={12} xs={12}>
              <Paper className={classes.controllerPaper}>
                <Grid container xs={12}>
                  <Grid lg={12} md={8}>
                    <EngineSelectPanel
                      setSelectEngines={setEnginePositions}
                      baseEngines={engines}
                      selectEngines={enginePositions}
                      isNoData={isNoData}
                      isNoEngine={isNoEngine}
                      vesselId={vesselId}
                      bhp={vesselBHP!=-1}
                    />
                  </Grid>
                  <Grid lg={12} md={4}>
                    <CppAnglePanel
                      cppAngles={latestEngineData
                        ?.filter((d) =>
                          engines
                            .map((e) => {
                              return e.position;
                            })
                            .includes(d.position)
                        )
                        .map((d) => ({
                          angle: d?.meCppBladeAngle,
                          dateTime: d?.dateTime,
                          position: d?.position,
                        }))}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </React.Fragment>
        )}
        {(vesselSettings?.NoDG > 0 || vesselSettings?.NoSG > 0) && (
          <Grid lg={12}>
          <Electric
            vesselId={vesselId}
            vesselSettings={vesselSettings}
            isInitialLoading={isInitialLoading}
            classes={classes}
          />
          </Grid>
        )}
      </Grid>
      {noPowers && isNoData && NoME <= 0 && vesselSettings?.NoDG <= 0 && vesselSettings?.NoSG <= 0 && (
        <Grid className={styles.noContent}>
          <NoData />
        </Grid>
      )}
    </React.Fragment>
  );
};

MonitoringPage.propTypes = {

};

export { MonitoringPage };
