import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import Logo from "../../../assets/images/defaults/Logo.png";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';

//Component import
import UserSettings from "./userSettings.jsx";

//API import
import { fetchVesselLatestAisData } from "../../api/latestData.js";

//Context imports
import UserContext from "../../context/UserContext.js";
import { NAVIGATION_BAR_HEIGHT } from "../../layout/panelLayout.jsx";
import { useDispatch, useSelector } from "react-redux";
import { selectSideBarState, setSideBarStatus } from "../../model/slice/sideBarSettingSlice";
import { ChevronLeft } from "@mui/icons-material";
import { useDetectVesselConnection } from "../../hooks/useDetectVesselConnection";
import useInterval from "../../hooks/useInterval";
import { fetchVesselInformationAsync } from "../../model/async/fleetNavigationDataAsync";
import {
  fetchFleetLatestEngineAsync,
  fetchFleetLatestEngineAsyncByVesselId,
} from "../../model/async/fleetEngineDataAsync";
import { fetchAlarmDataAsync } from "../../model/async/alarmAsync";
import localizationKeys from "../../i18n/localizationKeys";
import { withTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import dayjs from 'util/dayjs-init.js';
import { Link, useLocation } from "react-router-dom";
import VesselSelect from "./vesselSelect/vesselSelect.jsx";
import { detectDevice, DEVICE_TYPE } from "../../constants/detectDevice.js";
import { fleetNavigationData } from "../../model/slice/fleetNavigationDataSlice.js";
import VesselName from "./vesselSelect/vesselName.jsx";
import { logger } from "../../api/logger";
import { LATEST_UPDATE_FORMAT, isVesselOffline } from "../../constants/util";
import { fleetEngineData, latestEngineData } from "../../model/slice/fleetEngineDataSlice";

const titleFontSize = "16px";

const useStyles = ({ isMobile }) =>
  makeStyles((theme) => ({
    navigationBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#020202 !important",
      opacity: 1,
      height: NAVIGATION_BAR_HEIGHT + "px",
      // width: "100%",
      "user-select": "none",
      "-moz-user-select": "none",
      "-webkit-user-select": "none",
    },
    pageLogo: {
      height: "20px",
      width: "20px",
    },
    pageTitle: {
      color: "#ffffff",
      fontSize: titleFontSize,
      fontWeight: "200",
      margin: "auto, 0",
    },
    shipName: {
      marginLeft: "8px",
      fontFamily: "Inter",
      fontSize: titleFontSize,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      // lineHeight: "normal",
      letterSpacing: "normal",
      // textAlign: "top-center",
      color: "#19b2ab",
      margin: "auto, 0",
    },
    shipType: {
      // width: "194px",
      // height: "29px",
      // margin: "6px 257.5px 18px 0.5px",
      fontFamily: "Inter",
      fontSize: titleFontSize,
      fontWeight: "300",
      fontStretch: "normal",
      fontStyle: "normal",
      // lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff",
      margin: "auto, 0",
      marginLeft: "4px",
    },
    navLinks: {
      marginTop: "-10px",
    },
    sideBarIcon: {
      marginLeft: "0px",
    },
    pageTitleBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      // border: "solid white",
      overflow: "hidden",
      paddingTop: "2px",
      paddingBottom: "2px",
      width: "200px",
      height: `${NAVIGATION_BAR_HEIGHT}px`,
      "& p": {
        textOverflow: "ellipsis",
        // overflowWrap: "normal",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
        [theme.breakpoints.up("md")]: {
          marginLeft: "20px",
        },
        [theme.breakpoints.down('md')]: {
          marginLeft: "10px",
        },
      },
    },
    titleIconBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: `${NAVIGATION_BAR_HEIGHT}px`,
      width: "50px",
      // border: "solid white",
      minWidth: "30px",
    },
    userSettingBox: {
      // border: "solid white",
      height: `${NAVIGATION_BAR_HEIGHT}px`,
      maxWidth: "140px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        minWidth: "140px",
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: "30px",
      },
    },
    offline: {
      color: "#ff5252 !important",
      fontWeight: "bold",
      fontStyle: "normal",
      fontsize: "14px !important",
      fontStretch: "normal",
      fontHeight: "normal",
      letterSpacing: "normal",
      fontFamily: "Inter",
      [theme.breakpoints.up("md")]: {
        marginLeft: "10px",
      },
      textOverflow: "ellipsis",
      // overflowWrap: "normal",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      // [theme.breakpoints.down("sm")]: {
      //   marginLeft: "10px"
      // }
    },
    baseGrid: {
      height: `${NAVIGATION_BAR_HEIGHT}px`,
      justifyContent: "space-between",
    },
    titleGrid: {
      height: `${NAVIGATION_BAR_HEIGHT}px`,
      // border: "solid white",
      display: "flex",
      alignItems: "stretch",
    },
    latestUpdateGrid: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: `${NAVIGATION_BAR_HEIGHT}px`,
      overflow: "hidden",
      paddingTop: "4px",
      paddingBottom: "4px",
      // border: "solid white",
      "& p": {
        fontFamily: "Inter",
        fontSize: "17px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#c8c8c8",
        textOverflow: "ellipsis",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
      },
    },
    vesselSelect: {
      flexGrow: isMobile ? 2 : 1,
      // maxWidth: isMobile ? "250px" : null,
      [theme.breakpoints.up("lg")]: {
        minWidth: "300px",
      },
      [theme.breakpoints.only("md")]: {
        minWidth: "200px",
      },
      [theme.breakpoints.only("sm")]: {
        minWidth: "100px",
      },
    },
    vesselOfflineContainer: {
      alignItems: "center",
      justifyContent: "flex-start",
      display: "flex",
    },
  }));

const FETCH_INTERVAL = 1000 * 60 * 1;

const NavBar = ({
  title = null,
  vesselId = null,
  includeVesselSelect = true,
  changeLanguage,
  setUserContext,
  vesselName = "",
  vesselType = "",
  t = null,
}) => {
  const location = useLocation();
  const isMobile = detectDevice === DEVICE_TYPE.PHONE;
  const isPC = detectDevice === DEVICE_TYPE.PC;
  const classes = useStyles({isMobile})();
  const userContext = React.useContext(UserContext);
  const dispatch = useDispatch();
  const sideBarStatus = useSelector(selectSideBarState);
  const { connections, latestUpdates } = useDetectVesselConnection();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const vesselList = useSelector(fleetNavigationData);
  const vesselEngineList = useSelector(fleetEngineData);
  const engineData = useSelector(latestEngineData(vesselId));
  const [currentTime, setCurrentTime] = useState(Date.now());
  const isOffline = useMemo(() => {
    try {
      if (!vesselId || location.pathname.includes("main")) return false;
      if (vesselList?.fleetNavigationData.length === 1) {
        return isVesselOffline(
          vesselList?.fleetNavigationData?.[0],
          engineData
        );
      } else {
        return isVesselOffline(
          vesselList?.fleetNavigationData?.find((d) => d?.vessel_id === vesselId),
          engineData
        );
      }
    } catch (e) {
      logger.error(`isOffline error: ${e}`);
      return true;
    }
  }, [vesselId, vesselList]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setCurrentTime(Date.now()), 1 * 1000);
    return () => {
      clearTimeout(timeoutId);
    }
  }, [currentTime])

  const fetchVesselInfo = () => {
    dispatch(fetchVesselInformationAsync({ companyId: userContext.user.company_id }));
  };

  const fetchEngineData = () => {
    dispatch(fetchFleetLatestEngineAsync({ companyId: userContext.user.company_id }));
  };

  const fetchEngineDataByVesselId = () => {
    dispatch(fetchFleetLatestEngineAsyncByVesselId({ vesselId }));
  };

  const fetchAlarmData = () => {
    if (vesselList.fleetNavigationData?.length) {
      dispatch(
        fetchAlarmDataAsync({
          vesselIds: vesselList.fleetNavigationData.map((v) => v.vessel_id),
        })
      );
    }
  };

  useMemo(() => {
    if (window.location.pathname === "/main") {
      fetchEngineData();
    } else {
      fetchEngineDataByVesselId();
    }
    fetchAlarmData();
  }, [vesselList.fleetNavigationData]);

  useInterval(fetchVesselInfo, FETCH_INTERVAL, true);

  const getVesselAisInfo = async () => {
    try {
      if (!vesselId) {
        return;
      }

      const { latestAisData } = await fetchVesselLatestAisData(vesselId);

      if (latestAisData.length > 0) {
        const shipType = latestAisData[0].shipType !== undefined ? latestAisData[0].shipType : "";

        if (shipType === "") {
          return;
        }
      }
    } catch (e) {
      logger.error(`getVesselAisInfo error: ${e}`);
    }
  };

  React.useEffect(() => {
    getVesselAisInfo();
  }, []);

  const displaySideBarControl = () => {
    const icons = () => {
      return sideBarStatus ? <ChevronLeft /> : <MenuIcon />;
    };

    const clickMenuSelector = () => {
      dispatch(setSideBarStatus(!sideBarStatus));
    };

    return isXs ? (
      <IconButton
        className={classes.sideBarIcon}
        aria-label="open drawer"
        onClick={clickMenuSelector}
        edge="start"
        color="inherit"
        size="large">
        {icons()}
      </IconButton>
    ) : null;
  };

  const VesselNameOrSelector = useCallback(() => {
    switch (true) {
      //if emission page, hide vessel selector
      case location.pathname.match(/emission/g) !== null:
        return null;
      case vesselList?.fleetNavigationData?.length === 1:
        return (
          <VesselName
            vesselId={vesselList?.fleetNavigationData[0]?.vessel_id}
            vesselList={vesselList}
            vesselEngineList={vesselEngineList}
          />
        );
      case vesselList?.fleetNavigationData?.length > 1:
        return (
          <VesselSelect
            vesselId={vesselId}
            vesselList={vesselList}
            vesselEngineList={vesselEngineList}
          />
        );
      default:
        return null;
    }
  }, [vesselId, vesselList, vesselEngineList, location]);
  return (
    <AppBar position="fixed" className={classes.navigationBar}>
      <Grid container direction="row" alignItems="center" className={classes.baseGrid}>
        <Grid item xs={6} sm={"auto"} md={"auto"} lg={"auto"} className={classes.titleGrid}>
          {displaySideBarControl()}
          <div className={classes.titleIconBox}>
            <Link to="/main">
              <img src={Logo} className={classes.pageLogo} alt="logo" />
            </Link>
          </div>
          {!isXs || location.pathname === "/main" ? (
            <div className={classes.pageTitleBox}>
              <p>
                {/* if current display is XS or location in "/main", page name is not displayed */}
                <span className={classes.pageTitle}>{title}</span>
              </p>
            </div>
          ) : null}
          {includeVesselSelect && (
            <div className={classes.vesselSelect}>
              <VesselNameOrSelector />
            </div>
          )}
          {isOffline && isPC && location.pathname.match(/emission/g) === null ? (
            <div className={classes.vesselOfflineContainer}>
              <Typography variant={"body1"} className={classes.offline}>
                {t(localizationKeys.Disconnected_upper)}
              </Typography>
            </div>
          ) : null}
        </Grid>
        {connections.includes(vesselId) && !isXs ? (
          <Grid className={classes.latestUpdateGrid} lg="auto" sm={2} md={2} item>
            <p>{`${t(localizationKeys.LastUpdate_upper)}: ${dayjs
              .utc(latestUpdates.find((d) => d.vesselId === vesselId)?.lastDateTime)
              .format(LATEST_UPDATE_FORMAT)} (UTC)`}</p>
          </Grid>
        ) : null}
        {!isXs && (
          <Grid style={{ position: "absolute", right: isSm ? "95px" : "150px" }}>
            <div>{`${dayjs.utc(currentTime).format("HH:mm")} UTC`}</div>
            <div>{`${dayjs(currentTime).format("HH:mm")} LT`}</div>
          </Grid>
        )}
        <Grid item xs={"auto"} sm="auto" md={2} lg={2} className={classes.userSettingBox}>
          <UserSettings changeLanguage={changeLanguage} setUserContext={setUserContext} />
        </Grid>
      </Grid>
    </AppBar>
  );
};

NavBar.propTypes = {
  title: PropTypes.string,
  vesselId: PropTypes.string,
  changeLanguage: PropTypes.func,
  setUserContext: PropTypes.func,
  t: PropTypes.func.isRequired,
  vesselName: PropTypes.string,
  vesselType: PropTypes.string,
  includeVesselSelect: PropTypes.bool,
};

export default withTranslation()(NavBar);
