import { ENGINE_POSITION } from "../../constants/trendGraph/enginesLine";
import { isPassedBaseTime } from "../../constants/util";
import localizationKeys from "../../i18n/localizationKeys";
import { calcBHP } from "./brakeHorsePowerCalc";

export const engineSelect = (t, mountOfEngines) => {
  switch (mountOfEngines) {
    case 1:
      return [
        {
          number: 1,
          position: ENGINE_POSITION.CENTER,
          name: `${t(localizationKeys.MainEngine_upper)}`,
        },
      ];
    case 2:
      return [
        {
          number: 1,
          position: ENGINE_POSITION.PORT,
          name: `${t(localizationKeys.PortEngine)}`,
        },
        {
          number: 2,
          position: ENGINE_POSITION.STARBOARD,
          name: `${t(localizationKeys.StarboardEngine)}`,
        },
      ];
    case 3:
      return [
        {
          number: 1,
          position: ENGINE_POSITION.PORT,
          name: `${t(localizationKeys.PortEngine)}`,
        },
        {
          number: 2,
          position: ENGINE_POSITION.CENTER,
          name: `${t(localizationKeys.CenterEngine)}`,
        },
        {
          number: 3,
          position: ENGINE_POSITION.STARBOARD,
          name: `${t(localizationKeys.StarboardEngine)}`,
        },
      ];
    default:
      return [];
  }
};

export const engineDetector = (NoME, engineData) => {
  let target = null;
  switch (NoME) {
    case 1:
      target = engineData.filter((value) => value?.position === ENGINE_POSITION.CENTER);
      break;
    case 2:
      target = engineData.filter(
        (value) =>
          value?.position === ENGINE_POSITION.PORT || value?.position === ENGINE_POSITION.STARBOARD
      );
      break;
    case 3:
      target = engineData.filter(
        (value) =>
          value?.position === ENGINE_POSITION.PORT ||
          value?.position === ENGINE_POSITION.STARBOARD ||
          value?.position === ENGINE_POSITION.CENTER
      );
      break;
  }
  return target;
};

export const checkIsShownLoadDiagram = (NoME, engineData, vesselBHP) => {
  const detectedEngine = engineDetector(NoME, engineData);
  if (detectedEngine) {
    return (
      detectedEngine.filter(
        (value) => value?.meOutput !== null || value?.shaftHorsePower !== null
      ).length === 0 && vesselBHP == -1
    );
  }
  return true;
};

export const checkIsNoData = (NoME, engineData, gpvtgData) => {
  const detectedEngine = engineDetector(NoME, engineData);
  if (detectedEngine) {
    return (
      !detectedEngine
        .map((value) => {
          return isPassedBaseTime(value?.dateTime);
        })
        .includes(false) || isPassedBaseTime(gpvtgData?.dateTime)
    );
  }
  return true;
};
