import { useEffect, useRef, useState } from "react";
import {
    fetchChannelModAllData
  } from "../../../api/channel";
import { logger } from "../../../api/logger";

export const useChannelLatestData = ({ vesselId }) => {
  const unmount = useRef(false);
  const [latestChannelData, setLatestChannelData] = useState();
  const [isLoadingC, setIsLoading] = useState(false);
  let intervalId = null;

  const fetchData = async () => {
    setIsLoading(true);
    const data = await Promise.all([
        fetchChannelModAllData(vesselId)
    ])
      .then((d) => {
        logger.info(`fetchChannelLatestData data: ${JSON.stringify(d)}`);
        setIsLoading(false);
        return d;
      })
      .catch(() => {
        setIsLoading(false);
      });

    unmount.current || setLatestChannelData(data?.[0]?.channelData);
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      unmount.current = true;
    };
  }, []);

  useEffect(() => {
    fetchData();
    intervalId = setInterval(fetchData, 60000);
    
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [vesselId]);

  return {
    latestChannelData,
    isLoadingC
  };
};