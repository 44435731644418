import React, { useMemo, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedVessel,
  selectedVessel,
} from "../../../model/slice/fleetNavigationDataSlice.js";
import { fleetAlarmSelector } from "../../../model/slice/alarmSlice.js";
import { useNavigate, useLocation } from "react-router-dom";
import VesselIcon from "../../vesselImages/vesselIcon.js";
import * as PropTypes from "prop-types";
import { SHIP_TYPE, SHIP_GROUP_TYPE } from "../../../constants/vesselShipType.js";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { detectDevice, DEVICE_TYPE } from "../../../constants/detectDevice.js";
import { NAVIGATION_BAR_HEIGHT } from "../../../layout/panelLayout.jsx";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside.js";
import { VESSEL_TYPE } from "../../../model/slice/vesselSlice";
import { logger } from "../../../api/logger.js";
import { getAlarmHighestPriority, getMarkerColor } from "../../../helpers/alarmTypeStyle.js";
import SortUtil from "../../../helpers/sortUtil.js";
import { STATUS_ABNORMAL } from "../../../constants/alarmType.js";
import { isVesselOffline } from "../../../constants/util.js";
import { NAV_LINK_ID } from "../../../constants/navBar/navBar.js";

const MENU_BACKGROUND_COLOR = "#161a1e";

const customSelectStyle = ({ height, width, background }) => ({
  control: (base, state) => ({
    ...base,
    height,
    width,
    background,
    boxShadow: null,
    border: "none",
    minHeight: "40px",
    "& p": {
      overflowWrap: "normal",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
    },
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    background: "rgba(2,2,2,0.7)",
  }),
  valueContainer: (base, state) => ({
    ...base,
    height,
    overflowWrap: "normal",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height,
    "& svg": {
      fill: "#19b2ab",
    },
    "$ svg:checked": {
      fill: "#19b2ab",
      // opacity: 0.7
    },
    zIndex: 999,
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    zIndex: 9999,
    position: "absolute",
  }),
  input: (base, state) => ({
    ...base,
    display: "none",
    height: "100%",
    width,
  }),
  option: (base, state) => ({
    ...base,
    background: state.data === state.selectProps.value ? "#2c3a48" : "none",
    "&:hover": {
      background: "rgba(44, 58, 72, 0.6)",
    },
  }),
  menuList: (base) => ({
    background: "#161a1e",
    color: MENU_BACKGROUND_COLOR,
    padding: 0,
    zIndex: 9999,
    maxHeight: `calc(${window.innerHeight}px - 66px)`,
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    overflowX: "hidden",
  }),
});

const vesselSelectorBase = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

const VESSEL_NAME = {
  color: "#19b2ab",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "bold",
  fontStretch: "normal",
  lineHeight: "normal",
};

const useStyles = makeStyles((theme) => ({
  vesselSelectorComponent: {
    ...vesselSelectorBase,
  },
  offlineVesselSelectorComponent: {
    ...vesselSelectorBase,
  },
  vesselName: {
    ...VESSEL_NAME,
    color: "#19b2ab",
  },
  offlineVesselName: {
    ...VESSEL_NAME,
    color: "#FFFFFF",
    opacity: "0.7",
  },
  selectBaseCharacter: {
    color: "#FFFFFF",
    opacity: "1",
    fontSize: "14px",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    overflowWrap: "normal",
    textOverflow: "normal",
    width: "100%",
  },
  offlineSelectBaseCharacter: {
    color: "#FFFFFF",
    opacity: "0.7",
    fontSize: "14px",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    overflowWrap: "normal",
    textOverflow: "normal",
    width: "100%",
  },
  offline: {
    color: "#ff5252",
    fontWeight: "bold",
    fontStyle: "normal",
    fontsize: "14px",
    fontStretch: "normal",
    fontHeight: "normal",
    letterSpacing: "normal",
    fontFamily: "Inter-Regular_Extra",
    [theme.breakpoints.up("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: "10px",
    },
  },
}));

const VesselSelect = (props) => {
  const isMobile = detectDevice !== DEVICE_TYPE.PC;
  const sortUtil = new SortUtil();
  const {
    width = "100%",
    height = `${NAVIGATION_BAR_HEIGHT}px`,
    vesselId,
    background = "#020202",
    vesselList,
    vesselEngineList,
  } = props;
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const currentVessel = useSelector(selectedVessel);
  const fleetAlarmData = useSelector(fleetAlarmSelector);
  useOnClickOutside([menuRef], () => {
    isMobile || setIsMenuOpen(false);
  });

  const paths = location.pathname.split("/");
  let currentPath = paths[1];
  if (currentPath === NAV_LINK_ID.trend) {
    currentPath += `/${paths[2]}/${paths[3]}`;
  } else if (currentPath === "cargo") {
    currentPath += `/${paths[2]}`;
  }

  useEffect(() => {
    // get current vessel id or selected vessel in main page
    currentPath === "main" && setSelectedValue(currentVessel?.vessel_id ?? "");
    currentPath !== "main" && setSelectedValue(vesselId ?? "");
  }, [vesselId, vesselList?.fleetNavigationData]);

  const selectorVesselList = useMemo(() => {
    const sortedList = sortUtil.vesselListSort(vesselList, vesselEngineList);
    return (
      sortedList?.map((d) => {
        const isOffline = isVesselOffline(
          d,
          vesselEngineList?.find((e) => d.vessel_id === e.vessel_id)
        );
        const isSelected = d.vesselId === currentVessel?.vessel_id;

        return {
          vessel_name: d.vessel_name,
          vessel_id: d.vessel_id,
          isOffline,
          vessel_firstClass: d.vessel_firstClass,
          vesselType: SHIP_GROUP_TYPE[SHIP_TYPE[d?.vesselLatestAisData[0]?.shipType]],
          vesselIcon: VesselIcon(
            d?.vesselType === VESSEL_TYPE.SEP ? 52 : d?.vesselLatestAisData[0]?.shipType ?? 0,
            0,
            1,
            isOffline,
            false
          ),
          alarmData: fleetAlarmData.filter((f) => f.vesselId === d.vessel_id),
          isSelected,
        };
      }) ?? []
    );
  }, [vesselList, vesselEngineList, fleetAlarmData]);

  const onClickSelectedVessel = (data) => {
    logger.info(`onClickSelectedVessel data: ${JSON.stringify(data)}`);
    setSelectedValue(data.vessel_id);
    // jump selected vessel's each information page
    currentPath !== "main" &&
      selectedValue !== data.vessel_id &&
      navigate(`/${currentPath}/${data.vessel_id}`);
    // set selected vessel on menu page
    dispatch(setSelectedVessel(data.vessel_id));
  };

  const VesselImage = ({ isOffline, vesselIcon, vesselName, alarmData }) => {
    const alarmHighestPriority = getAlarmHighestPriority(alarmData);
    return (
      <div
        style={{
          minWidth: "30px",
          position: "relative",
        }}
      >
        <img
          src={vesselIcon}
          alt={`vessel icon ${vesselName}`}
          style={{ zIndex: 999, position: "relative" }}
        />
        {!isOffline && alarmHighestPriority?.alarmStatus === STATUS_ABNORMAL && (
          <span
            className="marker fl-marker"
            style={{
              filter: alarmHighestPriority != null ? getMarkerColor(alarmHighestPriority) : "",
              width: "24px",
              height: "24px",
              top: "-14px",
              left: "-12px",
              position: "absolute",
              borderRadius: "50%",
              zIndex: 600,
            }}
          />
        )}
      </div>
    );
  };

  const SelectComponent = (props) => (
    <div className={classes.vesselSelectorComponent}>
      <VesselImage
        isOffline={props?.isOffline}
        vesselName={props?.vesselName}
        vesselIcon={props?.vesselIcon}
        alarmData={props?.alarmData}
      />
      <Typography
        variant="body2"
        className={
          props?.isOffline ? classes.offlineSelectBaseCharacter : classes.selectBaseCharacter
        }
      >
        <span className={props?.isOffline ? classes.offlineVesselName : classes.vesselName}>
          {props?.vessel_name}
        </span>
        {props?.vesselType ? ` - ${props?.vesselType}` : null}
        {props?.vessel_firstClass ? ` [${props?.vessel_firstClass}]` : null}
        {/*{props?.isOffline && props?.vessel_id === selectedValue ? <span className={classes.offline}>*/}
        {/*    &nbsp;VESSEL OFFLINE*/}
        {/*  </span> : null}*/}
      </Typography>
    </div>
  );

  return (
    <div ref={menuRef}>
      <Select
        menuIsOpen={isMenuOpen}
        options={selectorVesselList}
        value={selectorVesselList?.find((d) => d.vessel_id === selectedValue) ?? ""}
        formatOptionLabel={SelectComponent}
        onChange={(d) => onClickSelectedVessel(d)}
        styles={customSelectStyle({
          width,
          height,
          background,
        })}
        placeholder="No vessel selected"
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        style={{
          zIndex: 9999,
        }}
        onMenuClose={() => setIsMenuOpen(false)}
        onMenuOpen={() => setIsMenuOpen(true)}
      />
    </div>
  );
};

VesselSelect.propTypes = {
  vesselId: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  background: PropTypes.string,
  vesselList: PropTypes.object,
};

export default VesselSelect;
