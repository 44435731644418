import React from "react";
import PropTypes from "prop-types";
import { TimePicker } from "@blueprintjs/datetime";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../constants/colors";
import dayjs from "util/dayjs-init.js";
import "./timePicker.css";

const useStyles = makeStyles({
  timePickerContainer: {
    display: "flex",
    background: COLORS.componentBackColor,
    justifyContent: "flex-end",
  },
  startTimePicker: {
    position: "relative",
    right: "5%",
  },
  endTimePicker: {
    position: "relative",
    right: "15%",
  },
});

export const Timepicker = (props) => {
  const { time, setTime } = props;
  const classes = useStyles();

  //Date object can't use UTC so use JST time -9 (note: display utc time but timezone is jst)
  const defaultValue = dayjs(time).subtract(9, "h").toDate();

  const onChangeHandler = (newTime, updateTarget, updateFunction) => {
    //fix newTime to correct utc time.
    const newUTCTime = dayjs.utc(newTime).add(9, "h");
    const newHour = newUTCTime.get("hour");
    const newMinutes = newUTCTime.get("minute");

    const returnValue = dayjs.utc(updateTarget);
    returnValue.set("hour", newHour);
    returnValue.set("minute", newMinutes);
    updateFunction(returnValue);
  };
  return (
    <div className={classes.timePickerContainer}>
      <TimePicker
        className={classes.startTimePicker}
        showArrowButtons
        value={defaultValue}
        onChange={(v) => onChangeHandler(v, time, setTime)}
      />
    </div>
  );
};

Timepicker.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  setTime: PropTypes.func,
};
