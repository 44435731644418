import { axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session.js";

export const fetchVesselApplication = async () => {
  try {
    const { data } = await axios.get(`/vesselApplication`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return data;
  } catch (e) {
    return { vesselApplication: e };
  }
};

export const fetchAddon = async (addonName) => {
  try {
    const { data } = await axios.get(`/addon`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      params: {
        name: addonName,
      },
    });
    return data;
  } catch (e) {
    return { addon: e };
  }
};
